export function ordinalSuffix(num: number) {
  const lastNum = num % 10;
  const last2Num = num % 100;
  if (lastNum === 1 && last2Num !== 11) {
    return 'st';
  }
  if (lastNum === 2 && last2Num !== 12) {
    return 'nd';
  }
  if (lastNum === 3 && last2Num !== 13) {
    return 'rd';
  }
  return 'th';
}
