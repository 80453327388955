import { MaterialisedPropertyData } from '@property-folders/contract';
import { canonicalisers } from './formatting';

export interface TemplateData {
  data?: Partial<MaterialisedPropertyData>;
}

export function processTemplate(text: string | undefined, templateData: TemplateData | undefined) {
  if (!text) return '';

  let replacementSet: Record<ReplacementToken, string> | undefined = undefined;
  return text.replace(/(@@\w+)/gi, (substr) => {
    if (!replacementSet) {
      replacementSet = constructReplacementSet(templateData);
    }

    return isValidReplacementToken(substr)
      ? replacementSet[substr] || ''
      : '';
  });
}

export type ReplacementToken = `@@${string}`;
const validTokens = new Set<ReplacementToken>([
  '@@planDepositDueDate'
]);
export function constructReplacementSet(templateData: TemplateData | undefined): Record<ReplacementToken, string> {
  return {
    '@@planDepositDueDate': templateData?.data?.titleDivision?.planDue12
      ? '12 months after contract date'
      : date(templateData?.data?.titleDivision?.planDueDate, '______________________ **[insert date]**')
  };
}
export function isValidReplacementToken(text: string): text is ReplacementToken {
  return Boolean(text.match(/^@@\w+$/) && validTokens.has(text as ReplacementToken));
}

function date(value: string | undefined, defaultValue: string): string {
  if (!value) return defaultValue;
  const canon = canonicalisers.date(value);
  console.log('canonical date', canon);
  return canon && canon.display
    ? canon.display
    : defaultValue;
}
