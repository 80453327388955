import _, { difference } from 'lodash';
import { Maybe } from '../../../types/Utility';
import { chattelsOptionsSaa, exclusionOptions, cgtApplicationPersonOpts, tenancyTypeOpts, rentalPeriodOpts, poolSectionOpts, boolApplicableOpts, LegalJurisdiction, boolKnownOpts } from '../constants';
import { Predicate } from '../../../predicate';
import {
  blankField,
  fieldLabel,
  generateFieldTable,
  itemSection,
  knownPair,
  noborder,
  spaceStackLinesSideEffect,
  singleFieldTable,
  itemSubsection,
  generateCheckboxRows,
  freeTextArea,
  arbitraryFieldsRowTable,
  generateCheckboxInlineTextLine,
  confineWidthWithColumn,
  fieldBoolCheckboxes,
  fieldFocus,
  fieldsSpacing,
  freeTextContinuationArea,
  singleFieldOrFreeTextContinuationArea
} from '..';
import { Exclusions, Inclusions, LegacyTenancy, MaterialisedPropertyData, PoolCompliance, PoolComplyState, TenantAgreement, ContractSettlementType } from '@property-folders/contract';
import { subsectionTitles } from '../doc-constants/sales-agency-agreement';
import { subsectionTitles as contractSubsectionTitles } from '../doc-constants/sale-contract';
import { minimumFontSize } from '../constants';
import { quadStateFilter } from '../../quadStateFilter';
import { QuadState } from '../../../types/QuadState';
import { formatBI } from '../formatBI';
import { formatAct } from '../formatters/clauses';
import { FieldPlaceholderStyle } from '../standards';
import { settlementSubsection } from './contractSettlement';

export function settlementPeriodSection(settlementTime: Maybe<string>, isVariation?: boolean) {
  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: [settlementTime
      ? generateFieldTable(knownPair(
        {
          fieldName: 'Settlement period', fieldValue: [{ text: settlementTime, style: 'fieldContent' },
            {
              text: ' after Contract date.',
              style: 'tableFieldGeneralText'
            }], fieldColons: true, labelStyleOverride: undefined, contentStyleOverride: undefined, isVariation: isVariation
        }), undefined)
      : generateFieldTable([
        {
          border: noborder,
          text: [
            fieldLabel({ fieldName: 'Settlement period', fieldColons: true, styleOverride: undefined, isVariation: isVariation }),
            { text: '  30 days ', style: 'tableFieldOption' },
            { text: 'after Contract date or:  ', style: 'tableFieldGeneralText' }
          ]
        },
        blankField()
      ],['auto', '*'])],
    unbreakable: undefined,
    bookmark: ['subsection-settlement-period',...fieldFocus('sale.settlement')],
    isVariation
  });
}

export function particularsSection(
  itemNo: number,
  chattelObj: Maybe<{[k:string]: any, simple: string[]}>,
  exclusionObj: Maybe<{simple: string[]}>,
  encroach: Maybe<string>,
  notiWorks: Maybe<string>,
  specialTerms: Maybe<string>,
  cgtEnable: Maybe<boolean>,
  cgtPerson: Maybe<string>,
  gstWithholdEnable: Maybe<boolean>,
  poolCompliance: PoolCompliance | undefined,
  vendorGst: Maybe<boolean>,
  tenant: Maybe<object>,
  vendorWorksEnable: boolean | undefined,
  vendorWorks: Maybe<string>,
  encroachEnable: boolean | undefined,
  notiWorksEnable: boolean | undefined,
  specialTermsEnable: boolean | undefined,
  inputTaxed: boolean | undefined,
  moreData: {
    saaGstUnknowns: MaterialisedPropertyData['saaGstUnknowns'],
    tenantList: TenantAgreement[] | undefined,
    settlement: Maybe<ContractSettlementType>
  }
) {
  const sectionItems = [];
  const settlementSection = settlementSubsection(moreData.settlement, { showTitle: true, isSaa: true });

  sectionItems.push({ unbreakable: true, stack: [settlementSection, ...chattelsSection(chattelObj, chattelsOptionsSaa)] });
  sectionItems.push(...exclusionSection(exclusionObj));
  sectionItems.push(...tenantSection(tenant, moreData.tenantList));
  sectionItems.push(...encroachSection({ enable: encroachEnable, content: encroach }));
  sectionItems.push(...notiWorksSection({ enable: notiWorksEnable, content: notiWorks }));
  sectionItems.push(...vendorWorksSection({ enable: vendorWorksEnable, content: vendorWorks }));
  sectionItems.push(...gstWithholdingSection({ gstWithholdEnable }));
  sectionItems.push(...vendorGstSection({ vendorGst, inputTaxed, saaGstUnknowns: moreData.saaGstUnknowns }));
  sectionItems.push(...poolSection(poolCompliance?.present, poolCompliance?.complyState, poolCompliance?.nonComplyWorks, { saaMode: true }));

  return itemSection({ itemNo: itemNo, itemTitleParam: 'Property and Sale Particulars'.toUpperCase(), bookmark: 'bookmark_particulars', stackContent: sectionItems });
}

export function chattelsSection(chattelObj: Inclusions, chattelsOptions: typeof chattelsOptionsSaa, isVariation?: boolean, title?: string, contractMode?: boolean) {
  const sectionItems = [{
    text: title || subsectionTitles.inclusions,
    style: 'sectionSubTitle',
    margin: [0,0,0,2],
    fontSize: isVariation ? minimumFontSize : undefined
  }];
  const { cccDetail, cccEnable, simple: chattels } = chattelObj || {};

  const detailers = [];
  if (!Predicate.boolFalse(cccEnable) && !contractMode) {
    detailers.push(
      singleFieldTable({
        fieldName: 'Consumer credit chattel details',
        fieldValue: (cccEnable && cccDetail) || '',
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Other
      }));
  }
  if (Array.isArray(chattels)) {
    const labelMappedChattels = chattels.map(o=>{
      const obj = _.find(chattelsOptions, co=>co.name===o);
      return obj ? obj.label : o;
    });

    const chattelOptionsText = chattelsOptions.map(co=>co.label);
    sectionItems.push(generateCheckboxRows(chattelOptionsText, labelMappedChattels));

    const otherChattels = difference(labelMappedChattels, chattelOptionsText);
    otherChattels.length > 0 && sectionItems.push(singleFieldTable({ fieldName: 'Other Inclusions', fieldValue: otherChattels.join(', '), isVariation }));
    detailers?.length > 0 && sectionItems.push({ stack: spaceStackLinesSideEffect(detailers) });
  } else {
    sectionItems.push(generateCheckboxRows(chattelsOptions.map(o=>typeof o === 'string' ? o : o.label)));
    sectionItems.push(freeTextContinuationArea('Other Inclusions', '', 2, undefined, undefined, isVariation));
    detailers?.length > 0 && sectionItems.push({ stack: spaceStackLinesSideEffect(detailers) });
  }
  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(sectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-inclusions', 'field_focus_chattels'],
    isVariation
  });
}

export function exclusionSection(exclusionObj: Exclusions, isVariation?: boolean, title?: string) {
  const sectionItems = [{
    text: title || subsectionTitles.exclusions,
    style: 'sectionSubTitle',
    margin: [0,0,0,2],
    fontSize: isVariation ? minimumFontSize : undefined
  }];
  const exclusions = exclusionObj?.simple;

  if (Array.isArray(exclusions)) {
    const labelMappedExclusions = exclusions.map(o=>{
      const obj = _.find(exclusionOptions, co=>co.name===o);
      return obj ? obj.label : o;
    });

    const exclusionOptionsText = exclusionOptions.map(co=>co.label);
    sectionItems.push(generateCheckboxRows(exclusionOptionsText, labelMappedExclusions, 2));

    const otherExclusions = difference(labelMappedExclusions, exclusionOptionsText);
    otherExclusions.length > 0 && sectionItems.push(singleFieldTable({
      fieldName: 'Other Exclusions',
      fieldValue: otherExclusions.join(', ')
    }));
  } else {
    sectionItems.push(generateCheckboxRows(exclusionOptions.map(o=>typeof o === 'string' ? o : o.label), [], 2));
    sectionItems.push(freeTextContinuationArea('Other Exclusions', '', 2, undefined, undefined, isVariation));
  }
  return itemSubsection({
    subsectionTitle: undefined,
    titleLineContent: undefined,
    subsectionContent: spaceStackLinesSideEffect(sectionItems),
    unbreakable: undefined,
    bookmark: ['subsection-exclusions', 'field_focus_exclusions'],
    isVariation
  });
}

export const applicableFreeTextSection = (title: string, bookmark?: string|string[]|undefined, optionsVariant?: {'true': string, 'false': string} = boolApplicableOpts) => ({ enable, content, isVariation }: {enable?: boolean, content?: string, isVariation?: boolean}) => {
  const sectionItems = [];
  if (Predicate.emptyOrEqual(enable, true)) {
    sectionItems.push(freeTextArea({ content, linesIfEmpty: 3 }));
  }
  return itemSubsection({
    subsectionTitle: title,
    titleLineContent: {
      yesNoBox: true,
      currentVal: enable,
      trueLabel: optionsVariant['true'],
      falseLabel: optionsVariant['false']
    },
    subsectionContent: spaceStackLinesSideEffect(sectionItems, 1),
    bookmark: bookmark,
    isVariation
  });
};

export const encroachSection = applicableFreeTextSection(
  subsectionTitles.encroach,
  ['subsection-encroach', ...fieldFocus('encroach'), ...fieldFocus('encroachEnable')],
  boolKnownOpts
);

export const notiWorksSection = applicableFreeTextSection(
  subsectionTitles.notiWorks,
  ['subsection-noti-works', ...fieldFocus('notiWorks'), ...fieldFocus('notiWorksEnable')],
  boolKnownOpts
);

export const vendorWorksSection = applicableFreeTextSection(
  subsectionTitles.vendorWorks,
  ['subsection-vendor-works', ...fieldFocus('vendorWorks'), ...fieldFocus('vendorWorksEnable')],
);

export const specialWorksSection = applicableFreeTextSection(
  subsectionTitles.specialTerms,
  ['subsection-special-terms', ...fieldFocus('specialTerms')]
);

export function cgtSection(cgtEnable: Maybe<boolean>, cgtPerson: Maybe<string>, contractMode = false, isVariation?: boolean) {
  const sectionItems = [];
  if (Predicate.emptyOrEqual(cgtEnable, true)) {
    sectionItems.push({
      text: contractMode
        ? ['If sale price is $750,000 or more, the Vendor is required to deliver a tax clearance certificate prior to settlement and Clause 27 applies.']
        : 'If sale price is $750,000 or more, all Vendors will require a CGT clearance to avoid a withholding tax at settlement being paid to the ATO.',
      style: 'detailText' });
    !contractMode && sectionItems.push(singleFieldTable({
      fieldName: 'Application by', fieldValue: generateCheckboxInlineTextLine(
        cgtApplicationPersonOpts,
        cgtPerson,
        undefined,
        10.5
      ),
      isVariation
    }));
  }

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.cgtWith,
      titleLineContent: {
        yesNoBox: true,
        currentVal: cgtEnable,
        trueLabel: 'Applicable',
        falseLabel: 'Not applicable'
      },
      subsectionContent: spaceStackLinesSideEffect(sectionItems, 1),
      unbreakable: undefined, bookmark: ['subsection-cgt-with', 'field_focus_cgtEnable', 'field_focus_cgtPerson'],
      isVariation
    }
  );
}

export function gstWithholdingSection({ gstWithholdEnable }:{gstWithholdEnable: Maybe<boolean>}, { isVariation, contractMode }: {isVariation?: boolean, contractMode?: boolean} = {}) {
  const sectionItems = [
    { text: 'Is GST withholding at Settlement applicable?', margin: [0,fieldsSpacing,0,0] },
    generateCheckboxRows([
      { selectMatch: 'false', label: 'No' },
      { selectMatch: 'true', label: 'Vendor’s Representative to provide notice, if required' }
    ], gstWithholdEnable == null ? [] : [`${gstWithholdEnable}`], 6),
    {
      text: `Note: the Purchaser will be required to withhold an amount from the Purchase Price and remit it to the ATO ${contractMode ? 'in accordance with General Condition 22 ' : ''}where the Vendor is registered or required to be registered for GST and the Property is: a ‘new residential premises’ (other than those created through a substantial renovation); or ‘potential residential land’.`,
      italics: true
    }
  ];

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.gstWith, subsectionContent: spaceStackLinesSideEffect(sectionItems), unbreakable: undefined, bookmark: ['subsection-gst-with', 'field_focus_gstWithholdEnable'],
      isVariation
    }
  );
}

export function poolSection(
  present?: boolean,
  compliance?: PoolComplyState,
  works?:string,
  renderOpts?: {contractMode?: boolean, isVariation?: boolean, saaMode?: boolean}) {
  const { contractMode = false, isVariation, saaMode } = renderOpts??{};
  const sectionItems = [];

  if (Predicate.emptyOrEqual(present, true)) {
    sectionItems.push(...(
      present
        ? [
          {
            text: [
              'The Vendor acknowledges that on or before Settlement, the Vendor must ensure that the Pool complies with all Pool safety features.'
            ],
            margin: [0,fieldsSpacing,0,0],
            style: 'detailText'
          }
        ]
        : [
          {
            text: [
              'If there is a Pool on the Property, the Vendor must by law at or before Settlement ensure that the Pool complies with all Pool safety requirements.'
            ],
            margin: [0,fieldsSpacing,0,0],
            style: 'detailText'
          }
        ]
    ));
    if (!saaMode && !contractMode) {
    // Because styles no longer seem to propogate down the text tree, we have to do this here
      const pre93Style = compliance === PoolComplyState.complyPre93 ? 'content':undefined;
      const post93Style = compliance === PoolComplyState.complyPost93 ? 'content':undefined;
      sectionItems.push(generateCheckboxRows([
        {
          label: formatBI(`Swimming Pool on Property (constructed pre 1 July 1993) - compliant with ${formatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')} as amended`, undefined, pre93Style),
          selectMatch: PoolComplyState.complyPre93
        },
        {
          label: formatBI(`Swimming Pool on Property (constructed post 1 July 1993) - compliant with ${formatAct(LegalJurisdiction.SouthAustralia, 'PlanningDevelopmentAndInfrastructureAct2016')} as amended`, undefined, post93Style),
          selectMatch: PoolComplyState.complyPost93
        },
        !contractMode && {
          label: 'Unsure, Vendor to engage qualified party to assess',
          selectMatch: PoolComplyState.unknown
        },
        { other: true, label: 'Non compliant - specify details', selectMatch: PoolComplyState.nonComply, content: compliance === PoolComplyState.nonComply ? works : undefined }
      ].filter(Predicate.isTruthy), Predicate.isNotNullish(compliance)  ?  [compliance] : [], 1));
    }
  }

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.pool,
      titleLineContent: {
        yesNoBox: true, currentVal: present, trueLabel: poolSectionOpts.true, falseLabel: poolSectionOpts.false
      },
      subsectionContent: spaceStackLinesSideEffect(sectionItems),
      unbreakable: undefined,
      bookmark: ['subsection-pool', 'field_focus_pool'],
      isVariation
    }
  );
}

const quadStateRows = [
  { label: 'Yes', selectMatch: QuadState.True.toString() },
  { label: 'No', selectMatch: QuadState.False.toString() },
  { label: 'Unknown', selectMatch: QuadState.AssertUnknown.toString() }
];

export function vendorGstSection(
  { vendorGst, inputTaxed, saaGstUnknowns }: {vendorGst: Maybe<boolean>, inputTaxed?: boolean, saaGstUnknowns: MaterialisedPropertyData['saaGstUnknowns'] },
  { isVariation }: {isVariation?: boolean} = {}
) {
  const subSectionItems = [];

  const vendorReg = quadStateFilter(vendorGst, saaGstUnknowns?.vendorGst);
  const isInputTaxed = vendorReg === QuadState.NotSet ? QuadState.NotSet : quadStateFilter(inputTaxed, saaGstUnknowns?.inputTaxed);
  const mainOl = [
    { stack: spaceStackLinesSideEffect([

      { text: 'Is the Vendor registered, or required to be registered, for GST?', margin: [0, fieldsSpacing, 0, 0] },
      generateCheckboxRows(quadStateRows, [vendorReg.toString()], 6),
      { text: 'If ‘No’, GST is not applicable, and you can proceed to ‘Pool compliance’', italics: true }
    ]) }
  ];
  if (vendorReg !== QuadState.False) {
    mainOl.push({ stack: spaceStackLinesSideEffect([
      { text: ['If ‘Yes’ or ‘Unknown’ was selected, is the sale the supply of an existing ‘residential premises’ which is input taxed?'] },
      generateCheckboxRows(quadStateRows, [isInputTaxed.toString()], 6),
      ...(isInputTaxed === QuadState.True || isInputTaxed === QuadState.NotSet ? [{ text: 'If ‘Yes’, no GST is applicable.', italics: true }] : []),
      ...(isInputTaxed !== QuadState.True ? [{ text: 'If ‘No’ or ‘Unknown’, the sale may be considered a taxable supply. If it is a taxable supply, the parties will need to determine if the margin scheme is to apply, or if a relevant exemption applies (e.g. the supply of a going concern, or farmland supplied for a farming business). ', italics: true }] : [])
    ]) });
  }
  subSectionItems.push({
    ol: spaceStackLinesSideEffect(mainOl),
    margin: [0,fieldsSpacing,0,0]
  });

  if (isInputTaxed !== QuadState.True && vendorReg !== QuadState.False && (saaGstUnknowns?.inputTaxed || saaGstUnknowns?.vendorGst)) {
    subSectionItems.push({
      text: 'The Vendor advises it is seeking instructions in relation to the applicable GST treatment on the Sale.', style: 'content'
    });
  }

  subSectionItems.push({
    text: 'The Agent is not qualified to advise on the appropriate GST treatment. The Vendor and Purchaser must obtain their own independent professional advice in relation to the appropriate GST treatment.'
  });

  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.gstIssues,
      titleLineContent: undefined,
      subsectionContent: spaceStackLinesSideEffect(subSectionItems),
      unbreakable: undefined,
      bookmark: ['subsection-gst-rego', 'field_focus_vendorGst'],
      isVariation
    }
  );
}

export function individualTenancy(tenant: TenantAgreement, {
  index,
  contractMode = false,
  isVariation = false,
  multiple = false
}:{multiple?: boolean, index: number, contractMode?: boolean, isVariation?: boolean}) {

  const { type, tenantName, manageAgent, start, end, rentalValue, period, bondEnable, bondAmt, adviseTenant, saleContTenant } = tenant || {};
  const sectionItems = [];
  const underTitle = [];
  underTitle.push(singleFieldTable(
    {
      fieldName: 'Agreement type', fieldValue: generateCheckboxInlineTextLine(tenancyTypeOpts, type, undefined, 10.5),
      isVariation
    }
  ));

  underTitle.push(singleFieldOrFreeTextContinuationArea('Tenant(s)', tenantName || '', 2, isVariation));

  if (!contractMode) underTitle.push(singleFieldTable({ fieldName: 'Managing agent', fieldValue: manageAgent, fieldPlaceholder: FieldPlaceholderStyle.Name }));
  type === 'fixed' || !type
    ? underTitle.push(arbitraryFieldsRowTable({
      fields: [['Tenancy start date', start], [(type ? 'End date' : 'End date (if fixed term)'), end]],
      isVariation,
      fieldPlaceholder: FieldPlaceholderStyle.Date
    }))
    : underTitle.push(confineWidthWithColumn(arbitraryFieldsRowTable({
      fields: [['Tenancy Start Date', start]],
      isVariation,
      fieldPlaceholder: FieldPlaceholderStyle.Date
    }), 240));

  //checkboxes cannot be inside the table or it messes up the height
  underTitle.push({
    columns: [
      { ...arbitraryFieldsRowTable({
        fields: [['Rent amount', rentalValue]],
        fieldColons: [true, false],
        labelStyleOverride: [undefined, 'fieldFontGeneral'],
        contentStyleOverride: undefined,
        relativeSpace: undefined,
        isVariation,
        fieldPlaceholder: FieldPlaceholderStyle.Price
      }), width: 'auto' },
      { text: ' per', width: 20, fontSize: 10.5 },
      { ...generateCheckboxInlineTextLine(rentalPeriodOpts, period, undefined, 10.5, { baselineOffset: -2 }, { baselineOffset: -5 }) }
    ]
  });

  const bondAmount= Predicate.emptyOrEqual(bondEnable, true) ? [{
    ...arbitraryFieldsRowTable({
      fields: [['Bond amount', bondAmt]],
      fieldColons: [true, false],
      labelStyleOverride: [undefined, 'fieldFontGeneral'],
      contentStyleOverride: undefined,
      relativeSpace: undefined,
      isVariation,
      fieldPlaceholder: FieldPlaceholderStyle.Price
    }),
    width: bondAmt ? 'auto' : 170
  }] : [];

  underTitle.push({
    columns: [
      { text: 'Bond with Consumer and Business Services: ', width: 'auto', style: 'tableFieldLabel' },
      { text: '', width: 5 },
      { ...fieldBoolCheckboxes(bondEnable, undefined, undefined, { baselineOffset: -2 }, { baselineOffset: -4 }), width: 'auto' },
      { text: '', width: 20 },
      ...(contractMode ? [] : bondAmount)
    ]
  });

  underTitle.push({ text: 'Note: only complete where a tenancy is to continue after Settlement. Do not complete if the Vendor will be providing vacant possession at Settlement.', italics: true });

  if (!contractMode) {
    underTitle.push(singleFieldTable(
      {
        fieldName: 'Upon sale Vendor to advise tenants',
        fieldValue: fieldBoolCheckboxes(adviseTenant),
        _: undefined,
        fieldColons: true,
        isVariation
      }
    ));
    underTitle.push(singleFieldTable(
      {
        fieldName: 'Sale is to be subject to existing tenancy continuing',
        fieldValue: fieldBoolCheckboxes(saleContTenant),
        _: undefined,
        fieldColons: true,
        isVariation
      },
    ));
  }
  sectionItems.push(spaceStackLinesSideEffect(underTitle));
  if (multiple) {
    return itemSubsection({
      isVariation,
      subsectionContent: sectionItems,
      subsectionTitle: `Tenancy ${index+1}`
    });
  }
  return sectionItems;
}

export function tenantSection(tenant?: LegacyTenancy, tenantList?: TenantAgreement[], contractMode = false, isVariation?: boolean) {
  const enable = tenant?.tenantEnable;
  const sectionItems = [];

  if (!Predicate.boolFalse(enable)) {
    const multipleEntries = tenantList?.length > 1;
    const items = (tenantList??[]).map((individualTenant, index) => individualTenancy(individualTenant, { multiple: multipleEntries, index, contractMode, isVariation }));
    if (items.length) {
      sectionItems.push({
        stack: spaceStackLinesSideEffect(items)
      });
    }
  }

  const underHang = !Predicate.boolFalse(enable) ? spaceStackLinesSideEffect(sectionItems) : [];
  return itemSubsection(
    {
      subsectionTitle: subsectionTitles.tenant,
      titleLineContent: { yesNoBox: true, currentVal: enable, trueLabel: boolApplicableOpts.true, falseLabel: boolApplicableOpts.false },
      subsectionContent: underHang,
      unbreakable: false,
      bookmark: ['subsection-tenancy', 'field_focus_saaTenant'],
      isVariation
    }
  );
}

export function encumbrancesSection(mattersAffectTitle: string | undefined) {
  return freeTextArea({
    title: {
      stack: spaceStackLinesSideEffect([
        {
          text: [
            { text: contractSubsectionTitles.permittedEncumbrances, style: 'sectionSubTitle' }
          ]
        },
        { text: ['The Property is sold subject to the following Encumbrances (existing or intended to be created):'] }
      ])
    },
    content: mattersAffectTitle,
    linesIfEmpty: 3,
    fieldTitle: undefined,
    bookmark: ['subsection-title-matters', ...fieldFocus('contractSchedule.noForm1NoCoolMatters')]
  });
}
