import { formatBI } from '../formatBI';

export function annexItemRemoved(listId: string, clauseHeading: string) {
  return formatBI(`Annexure ${listId} is deleted.`);
}

export function annexItemAdded(listId: string) {
  return formatBI(`Annexure ${listId} is inserted and is attached to this variation.`);
}

export function annexItemReplaced(listId: string) {
  return formatBI(`Annexure ${listId} is replaced and the replacement is attached to this variation.`);
}
