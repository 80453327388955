import Decimal from 'decimal.js';

export function minimumAdvertisingPrice(saleSubprops?: {
  agentEstPrc?: number;
  vendorPrc?: number;
  advertPrc?: number;
  advertPrcUpper?: number;
}): {upperDefault?: number, lowerDefault?: number} {
  const { agentEstPrc, vendorPrc, advertPrc: lowerPrice, advertPrcUpper: upperPrice } = saleSubprops || {};

  // pmap: Prescribed Minimum Advertising Price
  const pmap = typeof agentEstPrc === 'number' && typeof vendorPrc === 'number' && Math.max(agentEstPrc, vendorPrc);

  const lowerValid = typeof lowerPrice === 'number';
  const upperValid = typeof upperPrice === 'number';

  // Floor and ceil is to bring it to a whole dollar value, and keep it within the 10% range

  // We use the Decimal library here to avoid float rounding errors. Don't want there to be legal
  // rammifications because something was ever so slightly more than 10% by using a rougher solution.
  // Many other packages already use it as a dependency.
  if (lowerValid && !upperValid) {
    return { upperDefault: Decimal.floor(Decimal.mul(lowerPrice, 1.1)).toNumber() };
  }

  if (!lowerValid && upperValid) {
    return { lowerDefault: Decimal.max(pmap||0, Decimal.ceil(Decimal.div(upperPrice, 1.1))).toNumber() };
  }

  if (pmap && !lowerValid && !upperValid) {
    return { lowerDefault: pmap, upperDefault: Decimal.floor(Decimal.mul(pmap, 1.1)).toNumber() };
  }

  return {};
}