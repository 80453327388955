const disabled = {
  core: [
    // 'block',
    'abbr',
    'references',
    // 'inline',
    'footnote_tail',
    'abbr2',
    'replacements',
    'smartquotes'
  ],
  block: [
    'code',
    'fences',
    'blockquote',
    'hr',
    // 'list',
    'footnote',
    'heading',
    'lheading',
    'htmlblock',
    'table',
    'deflist'
    // 'paragraph',

  ],
  inline: [
    // 'text',
    // 'newline',
    'escape',
    'backticks',
    'del',
    'ins',
    'mark',
    // 'emphasis',
    'sub',
    'sup',
    'links',
    'footnote_inline',
    'footnote_ref',
    'autolink',
    'htmltag',
    'entity'
  ]
};

const INLINE_GROUP_DELIMS = [
  'text',
  'image',
  'softbreak',
  'hardbreak',
  '_close'
];

const getInnerTokens = (tokens: any[], start: number) => {
  const { type, level } = tokens[start];
  const tag = type.substring(0, type.length - 5);
  const endTag = `${tag}_close`;
  let end = start+1;

  let searching = true;

  while (searching) {
    if (tokens[end].type === endTag && tokens[end].level === level) {
      searching = false;
    }

    end++;
  }

  return tokens.slice(start+1, end-1);
};

const levelUp = (tokens: any, i: number, options: any, env: any, mdRenderer: any) => {
  const { level } = tokens[i];
  const innerTokens = getInnerTokens(tokens, i);
  return mdRenderer.render(innerTokens, options, env, level+1);
};

const listItemOpen = (tokens: any, i: number, options: any, env: any, mdRenderer: any) => {
  const { level } = tokens[i];
  const innerTokens = getInnerTokens(tokens, i);
  const result = mdRenderer.render(innerTokens, options, env, level+1);

  if (result.length === 2 && 'text' in result[0] && 'ol' in result[1]) {
    return [{
      stack: result
    }];
  }

  return result;
};

const orderedListOpen = (tokens: any, i: number, options: any, env: any, mdRenderer: any) => {
  const result = levelUp(tokens, i, options, env, mdRenderer);
  return { ol: result };
};

const renderRules = {
  ordered_list_open: orderedListOpen,
  list_item_open: listItemOpen,
  paragraph_open: levelUp
};

function _parseInlineGroup(group: any): { [key: string]: string | boolean } {
  const parsed: any = {};

  for (let i = 0; i < group.length; i++) {

    const item = group[i];
    switch (item.type) {

      case 'text':
        parsed.text = item.content;
        break;

      case 'strong_open':
        parsed.bold = true;
        break;

      case 'em_open':
        parsed.italics = true;
        break;

      case 'ins_open':
        parsed.decoration = 'underline';
        break;

      case 'link_open':
        parsed.link = item.href;
        break;

      case 'image':
        parsed.image = item.src;
        break;

      case 'softbreak':
      case 'hardbreak':
        parsed.text = '\n';
        break;

      default:
        break;
    }

  }

  return parsed;
}

// TODO: Potentially have a separate renderer to generate clauses using standard clause functions (`stack`, `ol`, `header`)
export function pdfMakeRenderer(md: any) {

  md.core.ruler.disable(disabled.core);
  md.block.ruler.disable(disabled.block);
  md.inline.ruler.disable(disabled.inline);
  md.inline.ruler.enable(['ins']);
  md.renderer.rules = renderRules; // this should be empty

  md.renderer.renderInline = (tokens: any, options: any, env: any) => {
    const groups = [];
    let group = [];
    const stacks = [];
    const delimiterRegExp = new RegExp(INLINE_GROUP_DELIMS.join('|') + '$');
    const sharedProps: { preserveLeadingSpaces?: boolean, margin?: number[] } = {};
    let textStack: { text: any[] } = { text: [] };
    let outputShouldBeStackStructured = false;

    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      group.push(token);

      if (delimiterRegExp.test(token.type) && token.level === 0) {
        groups.push([...group]);
        group = [];
      }
    }

    for (let i = 0; i < groups.length; i++) {
      const item = _parseInlineGroup(groups[i]);
      // if we encounter a newline, two things need to happen:
      // * we need to keep non-newline content grouped together
      //   * when you have a *line* of **varying** ~formatting~,
      //   it gets broken up into individual text objects, but you still want to display them together on the same line.
      // * we need to return content formatted into a stack of lines (outputShouldBeStackStructured)
      //   * this is because we want everything in this stack to belong to the same ordered list item,
      //   otherwise each line would be assigned individual numbers.
      // so, push whatever line content we've accumulated into the stack, and start a 'new line'.
      // later on, return it all as a 'stack' of lines instead of an array of text elements representing a single line.
      if (item.text === '\n') {
        outputShouldBeStackStructured = true;
        stacks.push(textStack);
        textStack = { text: [] };
        continue;
      }

      if (!item.text) {
        // if there is not text push the current stack and reset it
        if (textStack.text.length) {
          stacks.push(textStack);
          textStack = { text: [] };
        }

        // push the item
        stacks.push(item);
      } else {
        if (typeof item.text === 'string' && item.text.startsWith('|    ')) {
          const indent = item.text.replace('|', '').search(/\S|$/);
          item.text = item.text.replace('|', '').trimStart();

          if (indent > 0) {
            sharedProps.margin = [6*indent,0,0,0];
          }
        }

        if (typeof item.text === 'string' && item.text.trim() !== '') {
          textStack.text.push(item);
        }

        // Has child text below a list item
        // Example:
        // This is text.
        // 1. This is my first list item.
        //    This is child text below first item.
        //    1.1 This is a nested list.
        //    1.2 This is a nested list.
        // 2. This is my second list item.
        if (typeof item.text === 'string' && item.text === '\n') {
          outputShouldBeStackStructured = true;
        }
      }
    }

    // if there are still items left in the stack push it
    if (textStack.text.length) {
      stacks.push(textStack);
    }

    return outputShouldBeStackStructured
      ? [{ stack: stacks }]
      : stacks;
  };

  md.renderer.render = (tokens: any, options: any, env: any, level = 0) => {
    let result: any = [];
    for (let i = 0; i < tokens.length; i++) {
      const token = tokens[i];
      if (token.level !== level) {
        continue;
      }

      let renderedItem = null;

      if (token.type === 'inline') {
        renderedItem = md.renderer.renderInline(token.children, options, env);
      } else if (typeof md.renderer.rules[token.type] === 'function') {
        renderedItem = md.renderer.rules[token.type](tokens, i, options, env, md.renderer);
      }

      if (renderedItem) {
        result = result.concat(renderedItem);
      }
    }

    // this function is called recursively and we only want the margin on the top level elements
    if (level === 0) {
      // skip the first
      for (let i = 1; i < result.length; i++) {
        const r = result[i];

        if (!r.margin) {
          r.margin = [0, 0, 0, 0];
        }

        r.margin[1] = r.margin[1] > 0 ? r.margin[1] : 6;
      }
    }

    return result;
  };
}
