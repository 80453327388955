import { Annexure, ComparableAddress, SaleDetailsType, saleMethodOptions } from '@property-folders/contract';
import { Predicate } from '../../../predicate';
import { LegalJurisdiction, auctionOnSiteOptions } from '../constants';
import { minimumAdvertisingPrice } from '../../defaults';
import { arbitraryFieldsRowTable, blankField, bookmarkAnchor, drawUnderline, fieldFocus, fieldFocusMap, fieldLabel, fieldsSpacing, generateCheckboxRows, generateFieldTable, insertIf, itemSection, itemSection, itemSubsection, noborder, renderCheckboxBase, singleFieldTable, spaceStackLinesSideEffect } from '..';
import { mapOptsForCheckbox } from '../display-transformations';
import { canonicalisers } from '../../formatting';
import { minimumFontSize } from '../constants';
import { formatBI } from '../formatBI';
import { bold, formatAct } from '../formatters/clauses';
import type { Content } from 'pdfmake/interfaces';
import { FieldPlaceholderStyle } from '../standards';

const noteLine = (note: Content[]) => {
  return { text: note, margin: [2,fieldsSpacing,0,0], style: 'detailText' };
};
const fieldAndNote = (...lines) => {
  return {
    stack: lines.filter(a=>a)
  };
};

export function saleSection(
  itemNo: number,
  saleOpts: SaleDetailsType,
  compareAddrs: ComparableAddress[],
  disableCompareAlreadyProvided: boolean,
  {
    annexureRef,
    annexures = [],
    isVariation
  }:{
    annexureRef: string
    annexures?: Annexure[]
    isVariation?: boolean
  }
) {
  const { advertRange, advertPrc: lowerRaw, advertPrcUpper: upperRaw, vendorPrc, agentEstPrc } = saleOpts || {};

  const rangeDefaults = minimumAdvertisingPrice({
    advertPrc: lowerRaw && canonicalisers.aud(lowerRaw).canonical,
    advertPrcUpper: upperRaw && canonicalisers.aud(upperRaw).canonical,
    vendorPrc: vendorPrc && canonicalisers.aud(vendorPrc).canonical,
    agentEstPrc: agentEstPrc && canonicalisers.aud(agentEstPrc).canonical
  });
  const advertPrc = lowerRaw || canonicalisers.aud(rangeDefaults.lowerDefault).display;
  const advertPrcUpper = upperRaw || canonicalisers.aud(rangeDefaults.upperDefault).display;

  const rangeStyle = advertRange === 'range' ? 'content' : undefined;
  const setStyle = advertRange === 'set' ? 'content' : undefined;
  const subItems: any[] = [
    insertIf(!(['set', 'noprice'].includes(advertRange??'') && !agentEstPrc && !vendorPrc),
      singleFieldTable(
        {
          fieldName: 'Agent’s Estimated Selling Price',
          fieldValue: saleOpts?.agentEstPrc || '',
          _: undefined,
          fieldColons: true,
          labelStyleOverride: undefined,
          isVariation: isVariation,
          fieldPlaceholder: FieldPlaceholderStyle.Price
        }),
      fieldAndNote(
        singleFieldTable(
          {
            fieldName: 'Vendor’s Selling Price',
            fieldValue: saleOpts?.vendorPrc || '',
            _: undefined,
            fieldColons: true,
            labelStyleOverride: undefined,
            isVariation: isVariation,
            fieldPlaceholder: FieldPlaceholderStyle.Price
          }),
        noteLine(formatBI(`Note: Both of the above prices must be expressed as a single figure without any qualifying words or symbols (section 20(1)(b) ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.`))
      ),
      fieldAndNote(
        {
          text: 'Advertised price',
          style: 'sectionSubTitle',
          margin: [0,5,0,0],
          fontSize: isVariation ? minimumFontSize : undefined
        },
        noteLine(formatBI(`Note: The Prescribed Minimum Advertising Price (‘${bold('PMAP')}’) is the higher of the Agent’s Estimated Selling Price and the Vendor’s Selling Price. Advertising or price representations cannot be below the PMAP and if a range is used, the upper limit of the range cannot be more than 10% above the lower limit. The Agent’s estimate is the Agent’s genuine estimate based on comparable sales and market research and is not a valuation. The Vendor may request a written valuation be obtained from a valuer at the Vendor’s cost (section 24A(1)(d) ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.`))
      ),
      { text: '', margin: [0,0,0,fieldsSpacing] }
    ),
    generateCheckboxRows([
      {
        other: false,
        selectMatch: 'set',
        label: {
          text: [
            { text: 'Market with a set price ', style: setStyle },
            { text: advertRange === 'set' && advertPrc ? advertPrc : drawUnderline(FieldPlaceholderStyle.Price), style: 'content' }
          ]
        }
      },
      {
        other: false,
        selectMatch: 'range',
        label: {
          text: [
            { text: 'Market with a range ', style: rangeStyle },
            { text: advertRange === 'range' && advertPrc ? advertPrc : drawUnderline(FieldPlaceholderStyle.Price), style: 'content' },
            { text: ' to ', style: rangeStyle },
            { text: advertRange === 'range' && advertPrcUpper ? advertPrcUpper : drawUnderline(FieldPlaceholderStyle.Price), style: 'content' }
          ]
        }
      },
      {
        other: false,
        selectMatch: 'noprice',
        label: 'Market with no price'
      }
    ], advertRange ? [advertRange] : [], 1)

  ];

  const comparesOK = Array.isArray(compareAddrs) && compareAddrs.map(
    c=>Object.entries(c).filter(([k,_])=> k!=='id').map(([_,v])=>v).filter(a=>a).length>0
  ).filter(a=>a).length>0;
  const bmText = [...fieldFocus('disableCompareAlreadyProvided', 'compareAddrs'), 'subsection-comparable-sales'];
  const bmTextEnd = bmText.map(bm=>`${bm}_!END`);

  const compareBody = [];
  const foundAnnexure = annexures?.find(annex=>annex.id === annexureRef);
  if (foundAnnexure) {
    compareBody.push({ text: formatBI(`Refer *-*Annexure ${foundAnnexure.label}*-* - ${foundAnnexure.name}`), style: 'sectionText' });
  } else if (disableCompareAlreadyProvided) {
    compareBody.push({
      text: 'Already provided',
      style: 'fieldContent'
    });
  } else {
    compareBody.push({
      type: 'lower-roman',
      separator: ['(', ')'],
      style: 'tableFieldLabelMini',
      fontSize: isVariation ? minimumFontSize : undefined,
      ol: spaceStackLinesSideEffect(((comparesOK && compareAddrs) || [{},{}]).map((cmp) => {
        return arbitraryFieldsRowTable({
          fields: [
            ['Address', cmp.addrLine, { fieldPlaceholder: FieldPlaceholderStyle.Location }],
            ['Sold price', cmp.soldPrc, { fieldPlaceholder: FieldPlaceholderStyle.Price }],
            ['Sold date', cmp.soldDate, { fieldPlaceholder: FieldPlaceholderStyle.Price }]
          ],
          fieldColons: true,
          labelStyleOverride: 'tableFieldLabelMini',
          contentStyleOverride: 'fieldContentMini',
          isVariation
        });
      }))
    });
    if (!comparesOK) compareBody.push({ stack: ['Or', renderCheckboxBase('Already provided')], margin: [5,0,0,0] });
  }

  subItems.push({ stack: [
    ...bmText.map(bookmarkAnchor),
    ...spaceStackLinesSideEffect([
      {
        text: 'Comparable sales',
        style: 'sectionSubTitle',
        fontSize: isVariation ? minimumFontSize : undefined
      },
      ...compareBody,
      ...insertIf(!disableCompareAlreadyProvided && !comparesOK, [])
    ]),
    ...bmTextEnd.map(bookmarkAnchor)
  ] });

  return itemSection(
    {
      itemNo: itemNo, itemTitleParam: 'SALE', bookmark: ['bookmark_sale', 'subsection-advert-price', ...fieldFocusMap({
        sale: [
          'agentEstPrc',
          'vendorPrc',
          'advertPrc',
          'advertPrcUpper',
          'advertRange'
        ]
      })], stackContent: itemSubsection({ subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: spaceStackLinesSideEffect(subItems), unbreakable: true }), superTitle: false, isVariation: isVariation
    });
}

export function saleMethodSection(itemNo: number, saleMethod: string, saleMethodOther: string, auctionOpts, isVariation?: boolean) {
  const content = [
    {
      text: 'Method of Sale:',
      style: 'tableFieldLabel',
      fontSize: isVariation ? minimumFontSize : undefined
    },
    generateCheckboxRows(mapOptsForCheckbox(saleMethodOptions,saleMethodOther), saleMethod ? [saleMethod] : [], 1)
  ];
  const { propDate, propTime, onSite, location } = auctionOpts || {};
  const isAuction = saleMethod === 'auction';

  if (!saleMethod || saleMethod === 'auction') {
    content.push(
      { text: 'If Auction:', style: 'sectionText', fontSize: isVariation ? minimumFontSize : undefined },
      {
        stack: spaceStackLinesSideEffect([
          !auctionOpts?.timeTbc && singleFieldTable({
            fieldName: 'Proposed Auction Date',
            fieldValue: isAuction ? propDate : '',
            _: undefined,
            fieldColons: true,
            labelStyleOverride: undefined,
            isVariation: isVariation,
            fieldPlaceholder: FieldPlaceholderStyle.Date
          }),
          !auctionOpts?.timeTbc && singleFieldTable({
            fieldName: 'Time',
            fieldValue: isAuction ? (propDate && !propTime ? 'TBA' : propTime) : '',
            _: undefined,
            fieldColons: true,
            labelStyleOverride: undefined,
            isVariation: isVariation,
            fieldPlaceholder: FieldPlaceholderStyle.Time
          }),
          // Only way to make the selection for time to be confirmed to go away is either actually say it is yet to be confirmed, or fill in a date/time
          !(propDate && !auctionOpts?.timeTbc) && singleFieldTable({
            fieldName: 'Date and time to be confirmed',
            fieldValue: auctionOpts?.timeTbc ? 'Yes' : 'Yes / No',
            _: undefined,
            fieldColons: true,
            labelStyleOverride: undefined,
            isVariation: isVariation,
            fieldPlaceholder: FieldPlaceholderStyle.Amount
          }),
          ( typeof onSite === 'boolean' && (onSite || location)
            ? singleFieldTable({
              fieldName: 'Location',
              fieldValue: onSite ? auctionOnSiteOptions.true : location,
              _: undefined,
              fieldColons: true,
              labelStyleOverride: undefined,
              isVariation: isVariation,
              fieldPlaceholder: FieldPlaceholderStyle.Location
            })
            : generateFieldTable([
              {
                text: [
                  fieldLabel({ fieldName: 'Location', fieldColons: true, styleOverride: undefined, isVariation: isVariation }),
                  { text: '    ' },
                  { text: 'on site', style: 'tableFieldOption' },
                  { text: '    ' },
                  { text: 'or:  ', style: 'tableFieldGeneralText' }
                ],
                border: noborder
              },

              blankField()
            ],
            ['auto', '*'])
          ),
          noteLine(formatBI(`Note: The reserve price at auction is to be specified in writing by the Vendor prior to the auction. The reserve price must not at any time before or during the auction, be set at an amount exceeding 110% of the Vendor’s Selling Price (section 24J(1)(ba) ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}).\n\nA sales agency agreement for the sale of residential land by auction may not be varied by increasing the amount specified in the agreement as the selling price sought by, or acceptable to, the Vendor (section 20(5a) ${formatAct(LegalJurisdiction.SouthAustralia, 'LandAndBusinessSaleAndConveyancingAct1994')}.`))
        ].filter(Predicate.isTruthy), 1)

      }
    );
  }
  return itemSection({
    itemNo: itemNo,
    itemTitleParam: 'METHOD OF SALE',
    bookmark: ['bookmark_saleMethod','subsection-sale-method',...fieldFocus('auction'),...fieldFocusMap({ sale: ['saleMethod', 'saleMethodOther'] })],
    stackContent: itemSubsection({ subsectionTitle: undefined, titleLineContent: undefined, subsectionContent: spaceStackLinesSideEffect(content.filter(Predicate.isTruthy)) }),
    isVariation
  });
}
