import { MaterialisedPropertyData } from '@property-folders/contract';
import { contactAddressDerivation } from '../contactAddressDerivation';
import { transformPropertyFolderDataForDisplay } from '../pdfgen/display-transformations';

export function vendorAddressChangeIfSameAs(propertyTransformed: MaterialisedPropertyData, lastSignedSnapData: MaterialisedPropertyData) {
  return function () {
    const primaryVendor = propertyTransformed?.vendors?.find(v => v.id === propertyTransformed?.primaryVendor);
    if (!primaryVendor?.addrSameAsSale) {
      // I don't think we need to do more complex checks. If we're moving away from this flag,
      // the vendor section has already updated. Same if we just introduced this flag
      return false;
    }
    const previousSameAddr = contactAddressDerivation('', true, propertyTransformed?.saleAddrs ?? []).contactAddress;
    const currentSameAddr = contactAddressDerivation(
      '',
      true,
      (transformPropertyFolderDataForDisplay(lastSignedSnapData))?.saleAddrs ?? []
    ).contactAddress;
    return previousSameAddr !== currentSameAddr;
  };
}
