import { ContractSettlementType, SaleDetailsType } from '@property-folders/contract';
import {
  drawUnderline,
  fieldFocus, fieldsSpacing, generateCheckboxTextColumns,
  itemSection,
  itemSubsection,
  spaceStackLinesSideEffect
} from '..';
import { Predicate } from '../../../predicate';
import { canonicalisers } from '../../formatting';
import { FieldPlaceholderStyle } from '../standards';

export function settlementSubsection(
  contractSettlement?: ContractSettlementType,
  opts?: {
    isVariation?: boolean,
    isOffer?: boolean,
    showTitle?: boolean,
    isSaa?: boolean
  }) {
  const { isOffer, isVariation, showTitle } = opts ?? {};
  const sectionItems = [];
  let singleOptionHit = false;
  const restrictInOfferMode = (value?: boolean) => {
    if (!isOffer) return value;
    return singleOptionHit ? undefined : value;
  };
  const updateOptionHit = (value?: boolean) => {
    if (isOffer) return;
    if (singleOptionHit) return;

    singleOptionHit = !!value;
  };

  sectionItems.push({ text: opts?.isSaa ? 'Settlement is preferred to be:' : 'The Settlement Date will be:', ...(showTitle?{ margin: [0,fieldsSpacing,0,0] }:{}) });

  sectionItems.push(generateCheckboxTextColumns(
    contractSettlement?.onDate && contractSettlement?.date
      ? `on ${contractSettlement.date}; or`
      : { text: ['on ', drawUnderline(FieldPlaceholderStyle.Location), '; or'].filter(Predicate.isTruthy) },
    restrictInOfferMode(contractSettlement?.onDate)
  ) );
  updateOptionHit(contractSettlement?.onDate);

  const onContractDaysText = ' Days after signing the Contract; or';
  const onContractDaysNumberText = contractSettlement?.onContractDays?.toString().replace(/[^\d]/g, '');
  sectionItems.push(generateCheckboxTextColumns(
    contractSettlement?.onContract && onContractDaysNumberText
      ? `${onContractDaysNumberText}${onContractDaysText}`
      : { text: [drawUnderline(FieldPlaceholderStyle.Amount), onContractDaysText] },
    restrictInOfferMode(contractSettlement?.onContract)
  ) );
  updateOptionHit(contractSettlement?.onContract);

  const onConditionDaysText = ' Business Days after the date on which all the Special Conditions and any conditional Additional Conditions are satisfied or waived.';
  sectionItems.push(generateCheckboxTextColumns(
    contractSettlement?.onCondition && contractSettlement?.onConditionDays
      ? `${canonicalisers.days(contractSettlement.onConditionDays).canonical}${onConditionDaysText}`
      : { text: [drawUnderline(FieldPlaceholderStyle.Amount), onConditionDaysText] },
    restrictInOfferMode(contractSettlement?.onCondition)
  ) );
  updateOptionHit(contractSettlement?.onCondition);

  sectionItems.push({ margin: [0,fieldsSpacing, 0, 0], text: 'If more than one option is selected above, the Settlement Date will be on the last to occur of the selected options.' });

  return itemSubsection({
    subsectionTitle: showTitle ? 'Settlement' : undefined, titleLineContent: undefined, subsectionContent: spaceStackLinesSideEffect(sectionItems),
    isVariation
  });
}

export function contractSettlementSection(
  itemNo: number,
  contractSettlement: ContractSettlementType | undefined,
  sale?: SaleDetailsType,
  isVariation?: boolean,
  isOffer?: boolean
) {
  const sectionItems = [];
  sectionItems.push(settlementSubsection(contractSettlement, isVariation, isOffer));
  return itemSection({
    itemNo: itemNo, itemTitleParam: 'Settlement'.toUpperCase(), bookmark: ['bookmark_contractSettlement', 'subsection-contract-settlement', ...fieldFocus('contractSettlement')], stackContent: spaceStackLinesSideEffect(sectionItems),
    isVariation
  });
}
