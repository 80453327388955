import { adminFeeDefaultMessage, auctionFeeDefaultMessage } from '@property-folders/common/util/pdfgen/constants';
import { v4 as uuid } from 'uuid';
import { FeeCalcMechanism } from '../../property';

export const StandardReaformsTransactionFeeCents = 5500;
export const StandardReaformsTransactionFeeDollarString = (StandardReaformsTransactionFeeCents / 100).toFixed(2);

type PrefillFolderFieldSpecialType = {
  _prefill?: {
    entries?: any[],
    value?: any
  }
  _children?: PrefillFolderFieldType;
};
export type PrefillFolderFieldType =
  | PrefillFolderFieldSpecialType
  | { [x: string]: PrefillFolderFieldType } & PrefillFolderFieldSpecialType;

/**Prefill values for a new property folder/transaction
 * At the time of writing, even though you can define default values of children, there is nothing
 * to set those values when a new child is created. Also at this time, there are no defaults for
 * children. These values are set at the transaction created time, and thus are only set once.
 */
export const residentialPrefill: PrefillFolderFieldType = {
  adminFee: {
    enable: { _prefill: { value: true } },
    strike: { _prefill: { value: true } },

    templatePrice: {
      _prefill: { value: adminFeeDefaultMessage }
    }
  },
  auctionFeeSwitches: {
    strike: { _prefill: { value: true } },
    feeDesc: { _prefill: { value: auctionFeeDefaultMessage } }
  },
  agency: {
    duration: { _prefill: { value: 90 } },
    start: { _prefill: { value: true } }
  },
  agencyExclusive: { _prefill: { value: true } },
  agentAuthority: { _prefill: { value: true } },
  authAssoc: { _prefill: { value: true } },
  cgtEnable: { _prefill: { value: true } },
  chattels: {
    cccEnable: { _prefill: { value: false } },
    simple: {
      _prefill: {
        entries: [
          'Fixed floor coverings',
          'Light fittings',
          'Window treatments',
          'Compliant smoke alarm'
        ]
      }
    }
  },
  clauses: { _prefill: { entries: [] } },
  compareAddrs: {
    _prefill: {
      entries: [{ id: uuid(), soldPrc: '', addrLine: '', soldDate: '' }]
    }
  },
  exclusions: {
    simple: {
      _prefill: {
        entries: [
          'Vendor’s and/or occupier’s personal effects',
          'Landline telephones',
          'Loose floor coverings',
          'Furniture',
          'Pot plants and all garden ornaments'
        ]
      }
    }
  },
  gstWithholdEnable: { _prefill: { value: false } },
  marketingFees: {
    _prefill: {
      entries: [
        {
          itemDesc:
            'Advertising/marketing (and/or adopt Periodic Press Promotion Budget, refer below)'
        },
        { itemDesc: 'Electronic marketing (eg internet)' },
        { itemDesc: 'Brochures, leaflets and mail-out' },
        { itemDesc: 'Signage and photography' },
        { itemDesc: 'Sketch/floor plan' }
      ]
    }
  },
  payTerm: {
    actualTerms: {
      _prefill: {
        value:
          'Payable within 7 days of account date with interest at 10% per annum on all outstanding monies'
      }
    },
    enable: { _prefill: { value: true } },
    strike: { _prefill: { value: true } }
  },
  pressBudget: {
    enable: { _prefill: { value: false } },
    strike: { _prefill: { value: true } }
  },
  benefits: {
    enable: { _prefill: { value: false } }
  },
  prevAgentEnable: { _prefill: { value: false } },
  professFee: {
    commissionMode: { _prefill: { value: FeeCalcMechanism.Set } },
    terms: {
      _prefill: {
        value:
          'The fees are credited at settlement if a commission agreed is greater than the fees. Fees not being a commission are payable in any event, regardless of a settlement being achieved or not.'
      }
    }
  },
  saaTenant: { tenantEnable: { _prefill: { value: false } } },
  sale: { settlement: { _prefill: { value: 30 } } },
  fixedSearchFees: {
    epfFee: { enable: { _prefill: { value: true } } },
    councilSearch: {
      enable: { _prefill: { value: true } },
      itemCost: { _prefill: { value: 500 } }
    }
  },
  uplift: {
    enablePrint: { _prefill: { value: true } },
    strike: { _prefill: { value: true } }
  },
  vendorGst: { _prefill: { value: false } },

  contractSettlement: {
    afterCondition: {
      _prefill: {
        value:
          'within 7 days after the date on which all the Special Conditions are satisfied, whichever is the later; or such other date as mutually agreed between the parties, in writing.'
      }
    },
    onDate: { _prefill: { value: false } },
    onContract: { _prefill: { value: true } },
    onContractDays: { _prefill: { value: 30 } }
  },
  contractSpecial: {
    financeDeadlineTime: { _prefill: { value: '17:00' } },
    propertyNotPartOfSecurity: { _prefill: { value: false } }
  },
  form1AndSearches: {
    whoSearches: {
      _prefill: { value: 'eckermanns' }
    }
  },
  transactionFee: {
    enabled: {
      _prefill: { value: true }
    },
    transactionFeeAmountCents: {
      _prefill: { value: StandardReaformsTransactionFeeCents }
    }
  }
};
