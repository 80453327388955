export const sectionTitles = {
  properties: { num: 1, title: 'PROPERTY' },
  purchasers: { num: 2, title: 'PURCHASER' },
  price: { num: 3, title: 'PRICE' },
  settlement: { num: 4, title: 'SETTLEMENT' },
  specialConditions: { num: 5, title: 'SPECIAL CONDITIONS' },
  additionalConditions: { num: 6, title: 'ADDITIONAL CONDITIONS' }
  // gstSale: { num: 4, title: 'GST CONDITIONS OF SALE' },
  // price: { num: 5, title: 'PRICE' },
  // particulars: { num: 7, title: 'PROPERTY AND SALE PARTICULARS' },
  // specialConditions: { title: 'Special Conditions' },
  // otherNonClauseConditions: { title: 'Other Conditions' }
};
