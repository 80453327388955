import { Predicate } from '../../../predicate';
import { formatBI } from '../formatBI';
import { NestedOlDocumentNode } from '../types/NestedOlDocumentNode';
import { postSymbolForceSpacer } from '../formatters/postSymbolForceSpacer';

export function setNumberingSideEffect(
  node: NestedOlDocumentNode | string,
  paramDepth = 0,
  parentIterator: number | null = null,
  currentPrefix = '',
  parentNode: null|NestedOlDocumentNode = null,
  parentIndex: string|null = null
): {node: NestedOlDocumentNode | string, olHere: boolean, maxListPos: number | null} {
  let maxListPos: number | null = null;
  if (typeof node !== 'object') {
    if (typeof node === 'string' && parentNode && Predicate.isNotNullish(parentIndex)) {
      const strFormatResult = formatBI(node);
      parentNode[parentIndex] = typeof strFormatResult === 'string' ? { text: strFormatResult } : strFormatResult;
      parentNode[parentIndex].unbreakable = true;
    }

    return { node, olHere: false, maxListPos };
  }

  const startingPosition = node.start ?? 1;
  if (node.preventNumberingRecursion) {
    return { node, olHere: false, maxListPos };
  }

  let olBelow = false;
  let olHere = false;
  if ('ol' in node) {
    const nodeChild = node.ol;
    const depth = node.sideEffectForceDepth ?? paramDepth;
    if (Array.isArray(nodeChild)) {
      olHere = true;

      if (depth === 0) {
        node.separator = ['', '.' + postSymbolForceSpacer];
      } else if (depth === 1) {
        node.separator = [currentPrefix, postSymbolForceSpacer];
      } else if (depth === 2) {
        node.separator = ['(',')'+postSymbolForceSpacer];
        node.type = 'lower-alpha';
      } else if (depth === 3) {
        node.separator = ['(', ')' + postSymbolForceSpacer];
        node.type = 'lower-roman';
      } else if (depth === 4) {
        node.separator = ['', '.' + postSymbolForceSpacer];
        node.type = 'upper-alpha';
      } else {
        node.separator = [currentPrefix, postSymbolForceSpacer];
      }

      for (const listPos in nodeChild) {
        const listPosInt = parseInt(listPos);
        const deeper = setNumberingSideEffect(nodeChild[listPos], depth+1, listPosInt, currentPrefix+(listPosInt+startingPosition)+'.', nodeChild, listPos);
        olBelow = olBelow || deeper.olHere;
      }

      maxListPos = nodeChild.length + 1;
    }
  } else if ('stack' in node) {
    const nodeChild = node.stack;
    if (Array.isArray(nodeChild)) {
      const depth = node.sideEffectForceDepth ?? paramDepth;
      for (const listPos in nodeChild) {
        const deeper = setNumberingSideEffect(nodeChild[listPos], depth, parentIterator, currentPrefix, nodeChild, listPos);
        olBelow = olBelow || deeper.olHere;
      }
    }
  }
  if (!olBelow && !olHere) {
    if ('stack' in node && !('unbreakable' in node)) {
      node.unbreakable = true;
    }
  }
  return { node, olHere: olBelow || olHere, maxListPos };
}
