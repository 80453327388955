export default `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<!-- Generator: Adobe Illustrator 27.0.1, SVG Export Plug-In . SVG Version: 6.00 Build 0)  -->

<svg
   version="1.1"
   id="Layer_1"
   x="0px"
   y="0px"
   viewBox="0 0 413.11701 100.1622"
   xml:space="preserve"
   sodipodi:docname="greatforms_logo_strap.svg"
   width="413.117"
   height="100.16219"
   inkscape:version="1.2.2 (732a01da63, 2022-12-09)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><defs
   id="defs119" /><sodipodi:namedview
   id="namedview117"
   pagecolor="#ffffff"
   bordercolor="#000000"
   borderopacity="0.25"
   inkscape:showpageshadow="2"
   inkscape:pageopacity="0.0"
   inkscape:pagecheckerboard="0"
   inkscape:deskcolor="#d1d1d1"
   showgrid="false"
   inkscape:zoom="32.213828"
   inkscape:cx="390.82596"
   inkscape:cy="64.413333"
   inkscape:window-width="3840"
   inkscape:window-height="2066"
   inkscape:window-x="-11"
   inkscape:window-y="1071"
   inkscape:window-maximized="1"
   inkscape:current-layer="Layer_1" />
<style
   type="text/css"
   id="style2">
	.st1{fill-rule:evenodd;clip-rule:evenodd;fill:#DB7D58;}
	.st2{fill-rule:evenodd;clip-rule:evenodd;fill:#D96027;}
	.st3{fill:#5b5a5d;}
	.st5{fill:#d85f27;}
	.st6{fill:#dcb8ae;}
	.st7{fill:#da7d58;}
	.st8{fill-rule:evenodd;clip-rule:evenodd;fill:#d85f27;}
	.st9{fill-rule:evenodd;clip-rule:evenodd;fill:#5a5b5d;}
	.st10{fill-rule:evenodd;clip-rule:evenodd;fill:#5a5b5d;}
	.st11{fill:#3E469D;}
	.st12{fill:none;}
	.st13{stroke:#FFFFFF;stroke-miterlimit:10;}
	.st14{fill:#847770;}
	.st15{fill:none;stroke:#FFFFFF;stroke-miterlimit:10;}
	.st16{fill-rule:evenodd;clip-rule:evenodd;fill:#FFFFFF;}
	.st17{fill:#FFFFFF;stroke:#000000;stroke-miterlimit:10;}
</style>

<rect
   style="fill:#ffffff;stroke:#ffffff;stroke-width:25.3964;stroke-opacity:1"
   id="rect211"
   width="23.267754"
   height="32.466835"
   x="70.304832"
   y="52.138851" /><g
   id="g101"
   transform="matrix(0.75936482,0,0,0.76041973,-47.627362,-16.472593)">
	<g
   id="g77">
		<g
   id="g65">
			<path
   class="st6"
   d="m 77.99,93.56 h -1.91 c -2.95,0 -5.34,-2.39 -5.34,-5.34 V 55.27 c 0,-1.73 0.84,-3.35 2.25,-4.36 l 28.54,-20.25 c 1.85,-1.31 4.33,-1.31 6.18,0 l 3.53,2.51 6.92,-4.91 -5.82,-4.13 c -4.63,-3.29 -10.83,-3.29 -15.46,0 L 68.35,44.38 c -3.53,2.5 -5.63,6.56 -5.63,10.89 v 32.95 c 0,7.38 5.98,13.36 13.36,13.36 h 1.91 z"
   id="path59"
   style="clip-rule:evenodd;fill:#dcb8ae;fill-rule:evenodd" />
			<path
   class="st7"
   d="m 100.29,114.96 h -0.46 c -3.69,0 -6.68,-2.99 -6.68,-6.68 V 67.1 c 0,-2.16 1.05,-4.19 2.81,-5.45 l 35.67,-25.31 c 2.31,-1.64 5.41,-1.64 7.73,0 l 3.15,2.23 8.65,-6.14 -6,-4.26 c -5.79,-4.11 -13.54,-4.11 -19.32,0 L 90.17,53.49 c -4.41,3.13 -7.03,8.2 -7.03,13.61 v 41.18 c 0,9.22 7.47,16.69 16.69,16.69 h 0.46 z"
   id="path61"
   style="clip-rule:evenodd;fill:#da7d58;fill-rule:evenodd" />
			<path
   class="st8"
   d="M 227.66,64.01 183.07,32.37 c -7.23,-5.13 -16.92,-5.13 -24.15,0 l -44.59,31.64 c -5.51,3.91 -8.79,10.26 -8.79,17.02 v 51.48 c 0,11.52 9.34,20.87 20.87,20.87 h 22.8 v -12.52 h -22.8 c -4.61,0 -8.35,-3.74 -8.35,-8.35 V 81.03 c 0,-2.7 1.31,-5.24 3.52,-6.81 l 44.59,-31.64 c 2.89,-2.05 6.77,-2.05 9.66,0 l 44.59,31.64 c 2.21,1.57 3.52,4.1 3.52,6.81 v 26.95 c 0,3.46 2.8,6.26 6.26,6.26 3.46,0 6.26,-2.8 6.26,-6.26 V 81.03 c -0.01,-6.76 -3.29,-13.1 -8.8,-17.02 z"
   id="path63"
   style="clip-rule:evenodd;fill:#d85f27;fill-rule:evenodd" />
		</g>

			<rect
   x="165.06"
   y="117.38"
   transform="rotate(-90,169.8718,145.071)"
   class="st9"
   width="9.6300001"
   height="55.369999"
   id="rect67"
   style="fill:#5a5b5d" />
		<path
   class="st9"
   d="m 182.29,102.72 c -0.01,-1.89 -0.48,-2.92 -1.39,-3.02 v -0.02 h -33.23 v 0 c -0.18,0 -0.36,0 -0.53,0.09 -0.59,0.31 -0.88,1.42 -0.86,3.08 0.02,1.67 0.33,2.68 0.94,2.84 0.15,0.04 0.3,0.04 0.45,0.03 v 0 h 33.23 v -0.02 c 0.9,-0.06 1.39,-1.11 1.39,-2.98 z"
   id="path69"
   style="fill:#5a5b5d" />
		<path
   class="st9"
   d="m 193.35,116.15 c -0.01,-1.89 -0.63,-2.92 -1.81,-3.02 v -0.02 h -43.2 v 0 c -0.23,0 -0.47,0 -0.69,0.09 -0.77,0.31 -1.14,1.42 -1.12,3.08 0.03,1.67 0.44,2.68 1.22,2.84 0.19,0.04 0.39,0.04 0.58,0.03 v 0 h 43.2 v -0.02 c 1.2,-0.06 1.83,-1.12 1.82,-2.98 z"
   id="path71"
   style="fill:#5a5b5d" />
		<path
   class="st9"
   d="m 193.47,129.67 c -0.01,-1.89 -0.63,-2.92 -1.81,-3.02 v -0.02 h -43.2 v 0 c -0.23,0 -0.47,0 -0.69,0.09 -0.77,0.31 -1.14,1.42 -1.12,3.08 0.03,1.67 0.44,2.68 1.22,2.84 0.19,0.04 0.39,0.04 0.58,0.03 v 0 h 43.2 v -0.02 c 1.2,-0.06 1.83,-1.12 1.82,-2.98 z"
   id="path73"
   style="fill:#5a5b5d" />
		<path
   class="st10"
   d="M 207.8,104.83 V 77.78 c 0,0 0,-0.02 0,-0.03 v -0.19 h -0.03 c -0.22,-1.32 -1.88,-8.68 -11.11,-9.14 V 68.4 h -53.3 v 0.04 c -9.38,0.63 -11.13,7.77 -11.37,9.12 h -0.03 v 0.19 c 0,0.02 0,0.03 0,0.03 v 25.04 11.96 c 0,0.02 0,0.03 0,0.03 v 22.67 4.39 0 2.03 c 1.75,9.85 13.45,9.48 13.45,9.48 h 0.34 v 0 h 49.27 v -0.01 l 0.53,0.01 c 0,0 10.66,0.37 12.26,-9.33 v -2.18 0 -1.45 -25.01 c 0,0 0,-0.01 0,-0.03 v -10.55 z m -65.22,-26.3 h 0.19 57.34 v 61.73 H 141.87 V 78.53 Z"
   id="path75"
   style="clip-rule:evenodd;fill:#5a5b5d;fill-rule:evenodd" />
	</g>
	<g
   id="g99">
		<path
   class="st5"
   d="m 247.75,85.29 c 0,-16.05 10.17,-25.59 23.22,-25.59 6.96,0 11.73,3.06 14.82,6.21 l -4.65,5.61 c -2.46,-2.4 -5.34,-4.26 -9.9,-4.26 -8.7,0 -14.55,6.75 -14.55,17.76 0,11.19 5.19,17.97 15.03,17.97 2.73,0 5.43,-0.78 6.99,-2.16 V 90.24 h -9.24 V 83.1 h 17.04 v 21.63 c -3.27,3.3 -8.94,5.82 -15.66,5.82 -13.23,0 -23.1,-9.03 -23.1,-25.26 z"
   id="path79"
   style="fill:#d85f27" />
		<path
   class="st5"
   d="m 296.68,72.81 h 7.08 l 0.66,6.57 h 0.24 c 2.62,-4.8 6.52,-7.47 10.47,-7.47 1.88,0 3.06,0.24 4.17,0.75 l -1.5,7.5 c -1.29,-0.36 -2.25,-0.6 -3.78,-0.6 -2.94,0 -6.48,2.01 -8.7,7.62 v 22.47 h -8.64 z"
   id="path81"
   style="fill:#d85f27" />
		<path
   class="st5"
   d="m 321.47,91.26 c 0,-11.97 8.31,-19.35 16.98,-19.35 9.96,0 15.21,7.2 15.21,17.49 0,1.65 -0.18,3.3 -0.38,4.2 H 329.9 c 0.72,6.54 4.92,10.26 10.86,10.26 3.15,0 5.85,-0.99 8.55,-2.67 l 2.94,5.37 c -3.51,2.37 -7.95,3.99 -12.63,3.99 -10.17,0 -18.15,-7.14 -18.15,-19.29 z m 24.72,-3.54 c 0,-5.67 -2.52,-9.12 -7.56,-9.12 -4.29,0 -8.07,3.21 -8.82,9.12 z"
   id="path83"
   style="fill:#d85f27" />
		<path
   class="st5"
   d="m 359.04,99.75 c 0,-7.89 6.54,-12 21.66,-13.62 -0.12,-3.96 -1.71,-7.26 -6.69,-7.26 -3.66,0 -7.11,1.65 -10.35,3.63 l -3.18,-5.76 c 4.05,-2.55 9.27,-4.83 15.12,-4.83 9.21,0 13.74,5.67 13.74,15.93 v 21.81 h -7.08 l -0.66,-4.08 h -0.24 c -3.3,2.82 -7.08,4.98 -11.43,4.98 -6.45,0 -10.89,-4.38 -10.89,-10.8 z m 21.66,0.03 V 91.5 c -9.99,1.26 -13.32,3.87 -13.32,7.59 0,3.25 2.22,4.68 5.31,4.68 3,0 5.28,-1.47 8.01,-3.99 z"
   id="path85"
   style="fill:#d85f27" />
		<path
   class="st5"
   d="M 399.81,97.23 V 79.68 h -5.25 V 73.2 l 5.7,-0.39 1.02,-10.05 h 7.17 v 10.05 h 9.39 v 6.87 h -9.39 v 17.53 c 0,4.33 1.68,6.46 5.22,6.46 1.26,0 2.76,-0.42 3.84,-0.84 l 1.47,6.36 c -2.01,0.66 -4.58,1.35 -7.53,1.35 -8.49,0.01 -11.64,-5.36 -11.64,-13.31 z"
   id="path87"
   style="fill:#d85f27" />
		<path
   class="st3"
   d="m 422.12,73.2 5.4,-0.39 h 15.39 v 6.87 h -20.79 z m 4.95,-4.2 c 0,-7.63 3.51,-13.23 12.21,-13.23 2.67,0 5.1,0.63 6.75,1.29 l -1.65,6.45 c -1.32,-0.57 -2.73,-0.87 -3.99,-0.87 -3.06,0 -4.68,1.93 -4.68,6.21 v 40.8 h -8.64 z"
   id="path89"
   style="fill:#5b5a5d" />
		<path
   class="st3"
   d="m 446.19,91.26 c 0,-12.27 8.34,-19.35 17.52,-19.35 9.18,0 17.52,7.08 17.52,19.35 0,12.21 -8.34,19.29 -17.52,19.29 -9.18,0 -17.52,-7.08 -17.52,-19.29 z m 26.16,0 c 0,-7.38 -3.24,-12.3 -8.64,-12.3 -5.4,0 -8.64,4.92 -8.64,12.3 0,7.38 3.24,12.24 8.64,12.24 5.4,0 8.64,-4.86 8.64,-12.24 z"
   id="path91"
   style="fill:#5b5a5d" />
		<path
   class="st3"
   d="m 489.8,72.81 h 7.08 l 0.66,6.57 h 0.24 c 2.62,-4.8 6.52,-7.47 10.47,-7.47 1.88,0 3.06,0.24 4.17,0.75 l -1.5,7.5 c -1.29,-0.36 -2.25,-0.6 -3.78,-0.6 -2.94,0 -6.48,2.01 -8.7,7.62 v 22.47 h -8.64 z"
   id="path93"
   style="fill:#5b5a5d" />
		<path
   class="st3"
   d="m 517.77,72.81 h 7.08 l 0.66,5.03 h 0.24 c 3.12,-3.25 6.69,-5.92 11.31,-5.92 5.4,0 8.48,2.46 10.17,6.6 3.53,-3.75 7.17,-6.6 11.85,-6.6 7.77,0 11.37,5.34 11.37,14.64 v 23.1 h -8.64 v -22 c 0,-6.06 -1.8,-8.34 -5.67,-8.34 -2.34,0 -4.86,1.5 -7.71,4.59 v 25.74 h -8.64 V 87.66 c 0,-6.06 -1.8,-8.34 -5.67,-8.34 -2.28,0 -4.86,1.5 -7.71,4.59 v 25.74 h -8.64 z"
   id="path95"
   style="fill:#5b5a5d" />
		<path
   class="st3"
   d="m 577.34,105.42 4.02,-5.49 c 3.27,2.55 6.44,4.11 10.17,4.11 4.02,0 5.91,-1.89 5.91,-4.41 0,-3.09 -3.99,-4.47 -7.92,-5.97 -4.83,-1.82 -10.35,-4.59 -10.35,-10.74 0,-6.39 5.16,-11.01 13.41,-11.01 5.13,0 9.24,2.08 12.24,4.41 l -3.96,5.31 c -2.58,-1.89 -5.17,-3.21 -8.07,-3.21 -3.7,0 -5.46,1.77 -5.46,4.08 0,2.91 3.72,4.02 7.71,5.49 4.98,1.86 10.56,4.26 10.56,11.13 0,6.33 -5.01,11.43 -14.31,11.43 -5.04,0 -10.35,-2.16 -13.95,-5.13 z"
   id="path97"
   style="fill:#5b5a5d" />
	</g>
</g><path
   class="st5"
   d="m 63.391778,39.92774 v -4.585333 c 0,0 2.604615,-3.178554 5.718018,0 v 8.121284 c 0,0 -2.521098,3.55116 -5.718018,0 z"
   id="path103"
   style="fill:#d85f27;stroke-width:0.759892" /><g
   id="g153"
   transform="matrix(0.75936482,0,0,0.76041973,-47.627362,-16.472593)">
	<path
   class="st3"
   d="m 247.58,126.6 h 5.56 c 3.62,0 6.22,1.24 6.22,4.78 0,3.39 -2.6,5.02 -6.22,5.02 h -3.3 v 7.17 h -2.26 z m 5.24,8.05 c 2.76,0 4.29,-1.07 4.29,-3.27 0,-2.23 -1.52,-3.03 -4.29,-3.03 h -2.98 v 6.3 z m -0.12,1.16 1.77,-1.12 5.42,8.88 h -2.56 z"
   id="path105"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 262.98,126.6 h 10.24 v 1.83 h -7.98 v 5.32 h 6.73 v 1.83 h -6.73 v 6.17 h 8.25 v 1.83 h -10.51 z"
   id="path107"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 280.9,126.6 h 2.55 l 6.01,16.97 h -2.39 l -3.1,-9.48 c -0.64,-1.9 -1.21,-3.76 -1.78,-5.72 h -0.11 c -0.55,1.97 -1.12,3.82 -1.76,5.72 l -3.13,9.48 h -2.3 z m -2.85,10.08 h 8.18 v 1.72 h -8.18 z"
   id="path109"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 291.87,126.6 h 2.26 v 15.15 h 7.75 v 1.83 h -10.01 z"
   id="path111"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 310.44,126.6 h 10.24 v 1.83 h -7.98 v 5.32 h 6.73 v 1.83 h -6.73 v 6.17 h 8.25 v 1.83 h -10.51 z"
   id="path113"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 323.41,141.37 1.35,-1.48 c 1.28,1.27 3.05,2.1 4.9,2.1 2.33,0 3.71,-1.11 3.71,-2.76 0,-1.73 -1.29,-2.29 -2.96,-2.99 l -2.55,-1.08 c -1.66,-0.67 -3.6,-1.87 -3.6,-4.37 0,-2.59 2.36,-4.5 5.59,-4.5 2.11,0 3.98,0.87 5.23,2.1 l -1.2,1.39 c -1.09,-0.98 -2.39,-1.59 -4.03,-1.59 -1.99,0 -3.31,0.96 -3.31,2.48 0,1.64 1.55,2.25 2.94,2.81 l 2.53,1.05 c 2.06,0.84 3.66,2.01 3.66,4.54 0,2.68 -2.32,4.82 -6.05,4.82 -2.49,0 -4.65,-0.97 -6.21,-2.52 z"
   id="path115"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 342.31,128.43 h -5.37 v -1.83 h 13.01 v 1.83 h -5.37 v 15.15 h -2.26 v -15.15 z"
   id="path117"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 355.7,126.6 h 2.55 l 6.01,16.97 h -2.39 l -3.1,-9.48 c -0.64,-1.9 -1.21,-3.76 -1.78,-5.72 h -0.11 c -0.55,1.97 -1.12,3.82 -1.76,5.72 l -3.13,9.48 h -2.3 z m -2.85,10.08 h 8.18 v 1.72 h -8.18 z"
   id="path119"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 368.98,128.43 h -5.37 v -1.83 h 13.01 v 1.83 h -5.37 v 15.15 h -2.26 v -15.15 z"
   id="path121"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 379.79,126.6 h 10.24 v 1.83 h -7.98 v 5.32 h 6.73 v 1.83 h -6.73 v 6.17 h 8.25 v 1.83 h -10.51 z"
   id="path123"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 403.12,126.6 h 2.55 l 6.01,16.97 h -2.39 l -3.1,-9.48 c -0.64,-1.9 -1.21,-3.76 -1.78,-5.72 h -0.11 c -0.55,1.97 -1.12,3.82 -1.76,5.72 l -3.13,9.48 h -2.3 z m -2.85,10.08 h 8.18 v 1.72 h -8.18 z"
   id="path125"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 413.71,136.57 v -9.96 h 2.26 v 10.02 c 0,4.08 1.8,5.36 4.12,5.36 2.35,0 4.21,-1.28 4.21,-5.36 V 126.6 h 2.17 v 9.96 c 0,5.45 -2.75,7.32 -6.38,7.32 -3.63,0 -6.38,-1.86 -6.38,-7.31 z"
   id="path127"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 429.69,141.37 1.35,-1.48 c 1.28,1.27 3.05,2.1 4.9,2.1 2.33,0 3.71,-1.11 3.71,-2.76 0,-1.73 -1.29,-2.29 -2.96,-2.99 l -2.55,-1.08 c -1.66,-0.67 -3.6,-1.87 -3.6,-4.37 0,-2.59 2.36,-4.5 5.59,-4.5 2.11,0 3.98,0.87 5.23,2.1 l -1.2,1.39 c -1.09,-0.98 -2.39,-1.59 -4.03,-1.59 -1.99,0 -3.31,0.96 -3.31,2.48 0,1.64 1.55,2.25 2.94,2.81 l 2.53,1.05 c 2.06,0.84 3.66,2.01 3.66,4.54 0,2.68 -2.32,4.82 -6.05,4.82 -2.49,0 -4.66,-0.97 -6.21,-2.52 z"
   id="path129"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 448.59,128.43 h -5.38 v -1.83 h 13.01 v 1.83 h -5.37 v 15.15 h -2.26 z"
   id="path131"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 459.4,126.6 h 5.56 c 3.62,0 6.22,1.24 6.22,4.78 0,3.39 -2.6,5.02 -6.22,5.02 h -3.3 v 7.17 h -2.26 z m 5.24,8.05 c 2.76,0 4.29,-1.07 4.29,-3.27 0,-2.23 -1.52,-3.03 -4.29,-3.03 h -2.98 v 6.3 z m -0.12,1.16 1.77,-1.12 5.42,8.88 h -2.56 z"
   id="path133"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 478.35,126.6 h 2.55 l 6.01,16.97 h -2.39 l -3.1,-9.48 c -0.64,-1.9 -1.21,-3.76 -1.78,-5.72 h -0.11 c -0.55,1.97 -1.12,3.82 -1.76,5.72 l -3.13,9.48 h -2.3 z m -2.85,10.08 h 8.18 v 1.72 h -8.18 z"
   id="path135"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 489.31,126.6 h 2.26 v 15.15 h 7.75 v 1.83 h -10.01 z"
   id="path137"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 502.47,126.6 h 2.26 v 16.97 h -2.26 z"
   id="path139"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 513.24,126.6 h 2.55 l 6.01,16.97 h -2.39 l -3.1,-9.48 c -0.64,-1.9 -1.21,-3.76 -1.78,-5.72 h -0.11 c -0.55,1.97 -1.12,3.82 -1.76,5.72 l -3.13,9.48 h -2.3 z m -2.85,10.08 h 8.18 v 1.72 h -8.18 z"
   id="path141"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 529.62,126.6 h 10.25 v 1.83 h -7.99 v 5.72 h 6.77 v 1.83 h -6.77 v 7.59 h -2.26 z"
   id="path143"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 541.69,135.03 c 0,-5.44 3.13,-8.74 7.59,-8.74 4.46,0 7.59,3.31 7.59,8.74 0,5.44 -3.13,8.86 -7.59,8.86 -4.46,0 -7.59,-3.42 -7.59,-8.86 z m 12.85,0 c 0,-4.23 -2.09,-6.84 -5.26,-6.84 -3.17,0 -5.26,2.61 -5.26,6.84 0,4.23 2.09,6.96 5.26,6.96 3.17,0 5.26,-2.73 5.26,-6.96 z"
   id="path145"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 560.69,126.6 h 5.56 c 3.62,0 6.22,1.24 6.22,4.78 0,3.39 -2.6,5.02 -6.22,5.02 h -3.3 v 7.17 h -2.26 z m 5.24,8.05 c 2.76,0 4.29,-1.07 4.29,-3.27 0,-2.23 -1.52,-3.03 -4.29,-3.03 h -2.98 v 6.3 z m -0.12,1.16 1.77,-1.12 5.42,8.88 h -2.56 z"
   id="path147"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 576.1,126.6 h 2.72 l 3.41,9.07 c 0.43,1.16 0.83,2.36 1.26,3.54 h 0.11 c 0.43,-1.18 0.8,-2.38 1.23,-3.54 l 3.37,-9.07 h 2.72 v 16.97 h -2.1 v -9.35 c 0,-1.48 0.17,-3.52 0.29,-5.02 H 589 l -1.41,3.87 -3.35,8.78 h -1.5 l -3.36,-8.78 -1.41,-3.87 h -0.11 c 0.11,1.5 0.29,3.54 0.29,5.02 v 9.35 h -2.05 z"
   id="path149"
   style="fill:#5b5a5d" />
	<path
   class="st3"
   d="m 594.49,141.37 1.35,-1.48 c 1.28,1.27 3.05,2.1 4.9,2.1 2.33,0 3.71,-1.11 3.71,-2.76 0,-1.73 -1.29,-2.29 -2.96,-2.99 l -2.55,-1.08 c -1.66,-0.67 -3.6,-1.87 -3.6,-4.37 0,-2.59 2.36,-4.5 5.59,-4.5 2.11,0 3.98,0.87 5.23,2.1 l -1.2,1.39 c -1.09,-0.98 -2.39,-1.59 -4.03,-1.59 -1.99,0 -3.31,0.96 -3.31,2.48 0,1.64 1.55,2.25 2.94,2.81 l 2.53,1.05 c 2.06,0.84 3.66,2.01 3.66,4.54 0,2.68 -2.32,4.82 -6.05,4.82 -2.49,0 -4.66,-0.97 -6.21,-2.52 z"
   id="path151"
   style="fill:#5b5a5d" />
</g></svg>
`
