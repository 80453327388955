import { MarketingTemplateItem, MarketingTemplateSection, MarketingTemplateSectionType } from '@property-folders/contract/yjs-schema/entity-settings';
import { canonicalisers } from './formatting';

export function marketingItemSummation (acc: number, cv: MarketingTemplateItem) {
  const canonObject = canonicalisers.audWithNegative(cv?.price);
  const newValue = cv?.enabled ? (typeof canonObject.canonical === 'number' ? canonObject.canonical : 0) : 0;
  return acc + newValue;
}

export function marketingSectionTotal (section: MarketingTemplateSection) {
  return (section.type === MarketingTemplateSectionType.individual
    ? section.items?.reduce(marketingItemSummation, 0)
    : section.enabled ? canonicalisers.audWithNegative(section.price).canonical as number||0 : 0) || 0;
}
