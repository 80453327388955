import { A4_WIDTH, MarginTuple, mmToPoints } from '../measurements';
import { Annexure } from '@property-folders/contract';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';

export const getAnnexureCoverSheetDefinition = (annexure: Annexure, brand: EntityBrandFormConfig) => {
  const pageMargins: MarginTuple = [mmToPoints(15), mmToPoints(15), mmToPoints(15), mmToPoints(25.4)];
  const [mLeft, mTop, mRight, mBottom] = pageMargins;
  const contentSpan = A4_WIDTH - mLeft - mRight;
  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    content: [
      {
        canvas: [
          {
            type: 'polyline',
            lineWidth: mmToPoints(0.8),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
            lineColor: brand.lineColour
          }

        ],
        margin: [0,0,0,10]
      },
      {
        text: `Annexure ${annexure.label}`,
        style: 'header',
        alignment: 'center'
      },
      {
        canvas: [
          {
            type: 'polyline',
            lineWidth: mmToPoints(0.8),
            closePath: false,
            points: [{ x: 0, y: 0 }, { x: contentSpan, y: 0 }],
            lineColor: brand.lineColour
          }

        ],
        margin: [0,10,0,20]
      },
      {
        text: annexure.name,
        style: 'header',
        alignment: 'center',
        fontSize: 18
      },
      {
        text: annexure.coversheetText,
        style: 'header',
        alignment: 'center',
        fontSize: 16,
        margin: [0,20,0,0]
      }
    ],
    pageMargins: () => {
      return pageMargins;
    },
    styles: {
      header: {
        fontSize: 24,
        bold: true,
        alignment: 'center'
      }
    }
  });
};
