import { Agent } from '@property-folders/contract';
import { arbitraryFieldsRowTable, fieldsSpacing, itemSubsection, spaceStackLinesSideEffect } from '..';
import { FieldPlaceholderStyle } from '../standards';

export function contractConjunctionSummary(agents: Agent[], authorised: boolean|undefined) {
  // We only show if explicitly enabled.
  if (authorised === false || authorised == null) return [];
  const emptyPageMode = authorised == null;
  const agentContent= [];
  const agentsFiltered = Array.isArray(agents) && agents.filter(agent=>agent.company);
  const noAgents = !(agentsFiltered && agentsFiltered.length > 0);
  if (noAgents) return [];

  // We only take the first agency

  const agent = agentsFiltered[0];

  agentContent.push({
    stack: spaceStackLinesSideEffect([
      arbitraryFieldsRowTable({ fields: [
        ['Agency', emptyPageMode ? '' : agent.company, { fieldPlaceholder: FieldPlaceholderStyle.Name }]
      ] }),
      arbitraryFieldsRowTable({ fields: [
        ['Salesperson', emptyPageMode ? '' : agent.salesp?.[0]?.name, { fieldPlaceholder: FieldPlaceholderStyle.Name }],
        ['Agency RLA', emptyPageMode ? '' : agent.rla, { fieldPlaceholder: FieldPlaceholderStyle.Rla }],
        ['Agency ABN', emptyPageMode ? '' : agent.abn, { fieldPlaceholder: FieldPlaceholderStyle.Abn }]
      ], overflowIfAllPresent: true })
    ]),
    margin: [0,fieldsSpacing,0,0]
  });

  return itemSubsection({ subsectionTitle: 'In conjunction with:', titleLineContent: undefined, subsectionContent: agentContent, unbreakable: undefined, bookmark: 'bookmark_agent' });
}
