import { formatBI } from '../formatBI';

export function clauseItemRemoved(listId: string | number, clauseHeading: string) {
  return formatBI(`Additional Condition ${listId} is deleted.`);
}

export function clauseItemAdded(listId: string | number) {
  return formatBI(`Additional Condition ${listId} is inserted as follows:`);
}

export function clauseItemUpdated(listId: string | number, originalClauseHeading: string) {
  return formatBI(`Additional Condition ${listId} is deleted and replaced with:`);
}
