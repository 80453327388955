import { PdfWorkerDocumentDefinition } from '../../../pdf/pdf-worker-types';
import * as PdfGen from '@property-folders/common/util/pdfgen';
import transparentPixel from '@property-folders/common/assets/transparent-pixel.js';
import { documentSubTitle } from '@property-folders/common/util/pdfgen';
import { sectionTitles as st } from '../../doc-constants/sales-agency-agreement';

export function sampleSaa({
  property,
  brand,
  objects,
  annexures
}: PdfWorkerDocumentDefinition) {
  return ({
    pageSize: 'A4',
    pageOrientation: 'portrait',
    image: {
      agencyLogo: objects?.agencyLogoImage || transparentPixel
    },
    content: [
      ...PdfGen.documentTitle('Sales Agency Agreement', 1, brand, false),
      { text: 'The Vendor agrees to appoint the Agent, and the Agent accepts the appointment, on the terms set out in this Agreement.', margin: [0, 8, 0, 0], style: 'detailText' },
      documentSubTitle('SCHEDULE', { margin: [0,24,0,12] }),
      ...PdfGen.propertySection({
        itemNo: st.properties.num,
        addresses: Object.values(property?.saleAddrs ?? []),
        titles: property?.saleTitles ?? [],
        titleDivision: property?.titleDivision ?? {},
        annexures,
        divisionPlanOptional: true
      })
    ]
  });
}
