import { Content } from 'pdfmake/interfaces';
import { DiffCollection } from '../../../form/DiffCollection';
import { isVariationPresent } from '../../../variations/isVariationPresent';

export function renderIfVariation(
  renderCallback: ()=>Content,
  pathWatchList: string[][],
  changeSet: DiffCollection|null|undefined,
  customConditionCallback?: ()=>boolean
) {
  if (!changeSet) {
    return null;
  }
  if (isVariationPresent(pathWatchList, changeSet)) {
    return renderCallback();
  }
  if (customConditionCallback?.()) {
    return renderCallback();
  }
  return null;
}
