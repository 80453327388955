
import { arbitraryFieldsRowTable, fieldsSpacing, freeTextArea, generateCheckboxRows, itemSection, itemSubsection, spaceStackLinesSideEffect } from '..';
import { ContractTermination, ContractTerminationDepositReturn } from '@property-folders/contract/property';
import { mapOptsForCheckbox } from '../display-transformations';
import { contractTerminationDepositOpts } from '../constants';
import { Remarkable } from 'remarkable';
import { pdfMakeRenderer } from '../pdfMakeRenderer';
import { FieldPlaceholderStyle } from '../standards';

const remarkable = new Remarkable();
remarkable.use(pdfMakeRenderer);

export function terminationDepositSection(termination: ContractTermination|undefined) {

  const content = [
  ];
  if (termination?.depositOption!=null) {
    content.push({ text: contractTerminationDepositOpts[termination.depositOption].label, style: 'content' });
  } else {
    content.push(
      generateCheckboxRows(
        mapOptsForCheckbox(contractTerminationDepositOpts),
        termination?.depositOption == null ? [] : [`${termination?.depositOption}`],
        1
      ));
  }
  if (termination?.depositOption == null || termination?.depositOption === ContractTerminationDepositReturn.Split) {
    content.push(arbitraryFieldsRowTable({
      fields: [
        ['Released to Vendor', termination?.depositOption != null ? termination?.splitVendorReleaseAmt : ''],
        ['Returned to Purchaser', termination?.depositOption != null ? termination?.splitPurchaserReturnAmt : '']
      ],
      fieldPlaceholder: FieldPlaceholderStyle.Amount
    }));
  }
  if (termination?.otherTerms || termination?.depositOption == null) {
    content.push({
      text: 'OTHER TERMS',
      style: 'sectionDetailSubTitle',
      margin: [0,fieldsSpacing*1.5,0,0]
    });
    const renderedItems = termination?.otherTerms
      ? ((remarkable.render(termination?.otherTerms) as unknown) as any[])
      : freeTextArea({ linesIfEmpty: 5 });
    content.push(...renderedItems);
  }

  const subsectionWrapper = itemSubsection({
    subsectionTitle: '',
    subsectionContent: spaceStackLinesSideEffect(content)
  });

  return itemSection({
    bookmark: 'bookmark_terminationDeposit',
    itemTitleParam: 'DEPOSIT',
    titleStyleOverride: 'sectionDetailSubTitle',
    stackContent: [subsectionWrapper],
    topMarginOverride: fieldsSpacing,
    titleMarginBottomOverride: fieldsSpacing
  });
}
