import { Content } from 'pdfmake/interfaces';

import { setNumberingSideEffect } from '../formatters/setNumberingSideEffect';
import { Predicate } from '../../../predicate';
import * as PdfGen from '..';
import { spaceStackLinesSideEffect, fieldsSpacing } from '..';
import { EntityBrandFormConfig } from '@property-folders/contract/yjs-schema/entity-settings';
import {
  InstanceHistory,
  MaterialisedPropertyData
} from '@property-folders/contract';
import { FormTypes } from '../../../yjs-schema/property/form';
import { friendlyDateFormatterFull } from '../../formatting/functions/friendlyDateFormatter';
import { buildSigningTimelines } from '../../dataExtract';
import { FormDescriptorRecord } from '../../../yjs-schema/property';
import { summariseAddressesOrTitles } from '../../../yjs-schema/property/headlineGenerator';
import { ISignatureSectionSigner } from '../sections/signatureSection';
import { reaformsLogo, transparentPixel } from '../logos';
import { generatePartiesSummaryString } from '../sections/statement-of-parties';
import { bold, breakAfter, clause, formatAct, ol, quote } from '../formatters/clauses';
import { LegalJurisdiction } from '../constants';
import { formatBI } from '../formatBI';

export function variationWrapContentInPage(
  variationContents: (Content|null)[],
  agencyLogoImage: undefined | Blob | string,
  title: string,
  brand: EntityBrandFormConfig,
  renderedSignatureSection: Content[],
  currentData: MaterialisedPropertyData,
  snapshotHistory: InstanceHistory,
  formType: FormDescriptorRecord,
  signers: ISignatureSectionSigner[],
  opts?: {
    alternativeElectronicSigningTerms: Content,
    alternativeCounterpartsTerms: Content,
    clause4ConsiderationText: string
  }
) {
  const { alternativeElectronicSigningTerms, alternativeCounterpartsTerms, clause4ConsiderationText } = opts??{};
  const contractNoun = formType.variationClassificationNoun ?? 'Agreement';

  const names = generatePartiesSummaryString(signers);

  const latestInstanceGrouping = buildSigningTimelines(snapshotHistory).instanceSets;
  const latestGroup = latestInstanceGrouping.at(-1) || [];
  const earliestInstance = latestGroup.at(0);
  const earliestTitle = earliestInstance ? FormTypes[earliestInstance.formCode].label : '';
  const subsequentVariations = latestGroup.slice(1).map(inst=> `as varied by ${FormTypes[inst.formCode].label} dated ${friendlyDateFormatterFull(new Date(inst?.signing?.session?.completedTime||0))}`);
  const subsequentVariationText = subsequentVariations.length > 0 ? ', ' + subsequentVariations.join(',') : '';
  const titleSummarised = summariseAddressesOrTitles(currentData);
  const completedTime = earliestInstance?.signing?.session?.completedTime ? new Date(earliestInstance?.signing?.session?.completedTime) : undefined;
  const result = {
    pageSize: 'A4',
    pageOrientation: 'portrait',
    images: {
      agencyLogo: agencyLogoImage || transparentPixel,
      ourProductLogo: reaformsLogo
    },
    content: spaceStackLinesSideEffect([
      ...PdfGen.documentTitle(title, 1, brand, false),
      {
        stack: spaceStackLinesSideEffect([
          { text: 'BETWEEN:', style: 'sectionDetailSubTitle' },
          { text: [names, { text: formatBI(` (${quote(bold('Parties'))})`) }] }
        ]),
        margin: [0,2*fieldsSpacing,0,2*fieldsSpacing]// documentTitle has no bottom margin, so we add top margin here here
      },
      {
        stack: spaceStackLinesSideEffect([
          { text: 'RECITALS:', style: 'sectionDetailSubTitle' },
          { text: formatBI(`Parties entered into a ${earliestTitle} in respect of ${titleSummarised ?? ''} dated ${completedTime ? friendlyDateFormatterFull(completedTime) : ''}${subsequentVariationText} (${quote(bold(contractNoun))})`) },
          { text: `The Parties have agreed to vary the terms of the ${contractNoun} on the terms of this Variation.` }
        ]),
        margin: [0,0,0,2*fieldsSpacing]
      },
      {
        stack: spaceStackLinesSideEffect([
          { text: 'IT IS AGREED AS FOLLOWS:', style: 'sectionDetailSubTitle' },
          {
            style: 'listMarker',
            thisLevelMarkerOverrides: { style: 'sectionDetailSubTitle' },
            ol: spaceStackLinesSideEffect([
              clause('Definitions and interpretation',
                'In this Variation, unless inconsistent with the context:',
                ol(
                  `Variation means this ${title};`,
                  `terms defined in the ${contractNoun} have the same meaning in this Variation; and`,
                  `if there is any inconsistency between the provisions of this Variation and the ${contractNoun}, the provisions of this Variation prevail to the extent of the inconsistency.`
                ),
                breakAfter()
              ),
              clause('Variations',
                `The parties agree the ${contractNoun} is varied as follows:`,
                ol(
                  variationContents.filter(Predicate.isNotNullish)
                )
              ),
              clause(`Terms of ${contractNoun}`,
                `Except as varied by this Variation, the terms of the ${contractNoun} remain unchanged and the Parties ratify and confirm their respective obligations under the ${contractNoun} as varied by this Variation.`
              ),
              clause4ConsiderationText ? clause('Consideration',
                clause4ConsiderationText
              ) : [],
              clause('Electronic signing and communications',
                alternativeElectronicSigningTerms ?? `The Parties acknowledge and agree to some or all of them signing this Variation and any other documents by digital and/or electronic signatures, together with authorising all communications relating to the sale of the Property (including the service of any notices under the Act) to be completed by electronic means via the party’s email address or mobile phone number specified in the ${contractNoun} or varied by this Variation or provided to the other party from time to time, in accordance with the ${formatAct(LegalJurisdiction.SouthAustralia, 'ElectronicCommunicationsAct2000')}.`
              ),
              clause('Counterparts',
                alternativeCounterpartsTerms ?? 'This Variation may be executed in any number of counterparts, and this has the same effect as if the signatures on the counterparts were on a single copy of this Variation. Without limiting the foregoing, if the signatures on behalf of one party are on different counterparts, this shall be taken to be, and have the same effect as, signatures on the same counterpart and on a single copy of this Variation.'
              ),
              clause('Acknowledgement',
                'The Agent agrees to provide to the Vendor a copy of this Variation within 48 hours of execution by the Parties.'
              )
            ])
          }
        ]).map(node => setNumberingSideEffect(node).node),
        margin: [0,0,0,2*fieldsSpacing]
      },
      {
        unbreakable: false,
        stack: [
          { text: PdfGen.format('\n\n*EXECUTED* as an agreement') },
          ...renderedSignatureSection
        ]
      }
    ]),
    pageMargins: (currentPage: number) => PdfGen.standardPageMargins(currentPage, false),
    footer: (currentPage: number, pageCount: number) => PdfGen.standardFooter(currentPage, title, false, pageCount),
    header: (currentPage: number) => PdfGen.standardHeader(currentPage, title, false, reaformsLogo, brand.agencyContact)
  };
  return result;
}

