import { DiffCollection } from '../form/DiffCollection';
import { isPathInHierarchy } from '../pathHandling';

export function isPathInAnyHierachy(path: string[], diffCol: DiffCollection | undefined | null) {
  if (!diffCol) {
    return false;
  }

  return Object.values(diffCol)
    .map(modH => isPathInHierarchy(path, modH))
    .reduce((acc,cv)=>acc||cv);
}
