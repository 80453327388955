import { nestedOl } from '../formatters/nestedOl';

export const VendorPaysForm1Terms = {
  Eckermanns: {
    stack: [
      'The Vendor instructs the Agent on behalf of the Vendor to:',
      {
        ol: [
          'order the required searches from Eckermann Property Forms; and',

          nestedOl(
            'request Eckermann Property Forms to contact the Vendor to:',
            [
              'arrange the preparation, signing and certification of the Form 1; and ',
              'obtain payment from the Vendor for the searches and the Form 1 preparation costs (if applicable).'
            ],
            false
          ),

          nestedOl(
            'Should the Vendor not engage Eckermann Property Forms to prepare the Form 1, then:',
            [
              'to the full extent permitted by law, the Vendor will indemnify the Agent against any claim or loss suffered by anyone for an incomplete or defective Form 1 being prepared by the Vendor’s selected Form 1 preparer, Conveyancer or Solicitor; and',
              'the Vendor will remain liable to pay Eckermann Property Forms for searches already ordered, pursuant to Eckermann Property Forms\' standard terms.'
            ],
            false
          )
        ]
      }
    ]
  },

  Vendor: [
    'The Vendor will engage their own Form 1 preparer, Conveyancer or Solicitor at their own cost to order the required searches, prepare, sign and certify the Form 1. To the full extent permitted by law, the Vendor will indemnify the Agent against any claim or loss suffered by anyone for an incomplete or defective Form 1 being prepared by the Vendor’s selected Form 1 preparer, Conveyancer or Solicitor.'
  ],

  Agent: {
    stack: ['At the Vendor’s cost, the Agent will order the required searches, and arrange preparation, signing and certification of the Form 1. ']
  },

  Neither: {
    ol: [
      nestedOl(
        'If option 1 is selected under ‘Form 1 and searches’ in Item 7 of the Schedule, then: ',
        [
          nestedOl(
            'the Vendor instructs the Agent on behalf of the Vendor to:',
            [
              'order the required searches from Eckermann Property Forms; and',
              nestedOl(
                'request Eckermann Property Forms to contact the Vendor to: ',
                [
                  'arrange the preparation, signing and certification of the Form 1; and',
                  'obtain payment from the Vendor for the searches and the Form 1 preparation costs (if applicable); and'
                ],
                false
              )
            ],
            false
          ),
          nestedOl(
            'Should the Vendor not engage Eckermann Property Forms to prepare the Form 1, then:',
            [
              'to the full extent permitted by law, the Vendor will indemnify the Agent against any claim or loss suffered by anyone for an incomplete or defective Form 1 being prepared by the Vendor’s selected Form 1 preparer, Conveyancer or Solicitor; and',
              'the Vendor will remain liable to pay Eckermann Property Forms for searches already ordered, pursuant to Eckermann Property Forms\' standard terms.'
            ],
            false
          )
        ],
        false
      ),
      'If option 2 is selected under \'Form 1 and searches\' in Item 7 of the Schedule, then the Vendor will engage their own Form 1 preparer, Conveyancer or Solicitor at their own cost to order the required searches, prepare, sign and certify the Form 1. To the full extent permitted by law, the Vendor will indemnify the Agent against any claim or loss suffered by anyone for an incomplete or defective Form 1 being prepared by the Vendor’s selected Form 1 preparer, Conveyancer or Solicitor.',
      'If option 3 is selected under \'Form 1 and searches\' in Item 7 of the Schedule, then at the Vendor’s cost, the Agent will order the required searches, and arrange preparation, signing and certification of the Form 1. '
    ]
  }
};

export const AgentPaysForm1Terms = {
  Eckermanns: {
    stack: [
      'The Agent advises and the Vendor acknowledges that the Agent will, at the Agent’s cost, instruct Eckermann Property Forms to:',
      {
        ol: [
          'order the required searches; and',
          'contact the Vendor to arrange the preparation, signing and certification of the Form 1.'
        ]
      }
    ]
  },

  Agent: {
    stack: [
      'The Agent will, at the Agent’s cost, order the required searches and prepare the Form 1.\n'
    ]
  },

  Vendor: [
    'If this clause is visible, the configuration of this form should be considered in error.\n'
  ],

  Neither: {
    ol: [
      nestedOl(
        'If option 1 is selected under \'Form 1 and searches\' in Item 7 of the Schedule, then the Agent advises and the Vendor acknowledges that the Agent will, at the Agent’s cost, instruct Eckermann Property Forms to:',
        [
          'order the required searches; and',
          'contact the Vendor to arrange the preparation, signing and certification of the Form 1.'
        ],
        false
      ),

      'If option 2 is selected under \'Form 1 and searches\' in Item 7 of the Schedule, the Agent will, at the Agent’s cost, order the required searches and prepare the Form 1.'
    ]
  }
};

export const saaGeneralConditionsForm1 = {
  vendor: VendorPaysForm1Terms,
  agent: AgentPaysForm1Terms
};
