import { Content } from 'pdfmake/interfaces';
import { attachStackFirstItem, fieldsSpacing, leftIndentSideEffect } from '..';
import { minimumFontSize } from '../constants';
function preprocessNode (elem: Content) {
  if (!Array.isArray(elem)) {
    return elem;
  }
  if (elem.length === 1) {
    return preprocessNode(elem[0]);
  }
  return { stack: elem.map(preprocessNode) };
}

export function variationItem(numberLineHeading: string|Content, content: Content|Content[]): Content {
  // This is a bit of a mess. We need to unwind the structure of item sections and item subsections
  // so that we can correctly apply the indent, and attach our variation replacement text to the
  // replacement section, so that we don't page break before we've even printed the section,
  // leaving the text declaring the replacement hanging.
  let arr = [content];
  let stackWrap = {};

  let bookmarkElem: Content | null = null;
  if (Array.isArray(content) && content.length === 1 && content[0].stack) {
    arr = content[0].stack;
    stackWrap = content[0];

    if (arr.length >= 2 && arr[1].lineHeight === 0 && arr[1].style === 'hiddenText' && arr[0].stack) {
      bookmarkElem = arr[1];
      arr = arr[0].stack;
    } else if (stackWrap.unbreakable && stackWrap.decoration) {
      // This is for targeting struck-through subsections. It cannot be applied when a bookmark is
      // found... for some reason. It results in multiple bookmarks with the same ID, which the
      // renderer doesn't like.
      arr = [stackWrap];
      stackWrap = { stack: arr, unbreakable: true };
    }

  } else if (Array.isArray(content?.stack)) {
    arr = content.stack;
    stackWrap = content;
  } else if (Array.isArray(content)) {
    arr = content;
  }

  arr = arr.map(elem => leftIndentSideEffect(preprocessNode(elem), 1.5));
  const lineText = { text: numberLineHeading, margin: [0,0,0,fieldsSpacing*1.5], fontSize: minimumFontSize };
  arr = attachStackFirstItem(arr, lineText, !!stackWrap.unbreakable, false);
  if (bookmarkElem) {
    arr = [
      { stack: arr },
      bookmarkElem
    ];
  }
  stackWrap.stack = arr;
  // There's basically no reason we would want the numbering to continue to the sub items
  stackWrap.preventNumberingRecursion = true;
  return stackWrap;

}
