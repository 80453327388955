import { PathType } from '@property-folders/contract/yjs-schema/model';
import { Annexure, Clause, InstanceHistory, MaterialisedPropertyData } from '@property-folders/contract';
import { DiffCollection } from '../../form/DiffCollection';
import {
  buildListHistory,
  determineReplacedRemovedLocked, processAnnexureHistory
} from '../../dataExtract';
import {
  DataGeneration
} from '../../dataExtractTypes';
import { buildListDiffSpec } from '../display-transformations';
import { getValueByPath } from '../../pathHandling';
import { variationItem } from '../variations/variation-li';
import { clauseItemAdded, clauseItemRemoved, clauseItemUpdated } from '../formatters/clause';
import { isolatedClauseItemRenderer } from '../sections/clausesSection';
import * as PdfGen from '..';
import { annexItemAdded, annexItemRemoved, annexItemReplaced } from '../formatters/annex';
import { find } from 'lodash';

export function buildClauseAndAnnexureDiffDisplay(
  clausesPath: PathType,
  snapshotHistory: InstanceHistory | undefined,
  changeSet: DiffCollection | undefined,
  propertyRaw: MaterialisedPropertyData,
  annexures: Annexure[],
  mutableExtraContent: Content[]
) {
  if (snapshotHistory && changeSet) {
    const listHistory = buildListHistory(clausesPath, snapshotHistory, snapshotHistory.latestExpiry);
    const rrl = determineReplacedRemovedLocked(clausesPath, listHistory, propertyRaw);
    const clauseChangeItems = buildListDiffSpec(rrl, getValueByPath(clausesPath, propertyRaw, true) ?? [], clausesPath, changeSet);
    if (clauseChangeItems.length > 0) {
      mutableExtraContent.push(...clauseChangeItems.map(changeItem => {
        if (changeItem.state === DataGeneration.Added) {
          const rawItem = variationItem(
            clauseItemAdded(changeItem.itemOrder + 1, changeItem.data.title),
            isolatedClauseItemRenderer(changeItem.data as Clause, changeItem.itemOrder)
          );
          return {
            stack: [rawItem],
            preventNumberingRecursion: true,
            margin: [0, 0, 0, PdfGen.fieldsSpacing]
          };
        }
        if (changeItem.state === DataGeneration.CarriedOver) {
          const rawItem = variationItem(
            clauseItemUpdated(changeItem.itemOrder + 1, changeItem.data.title),
            isolatedClauseItemRenderer(changeItem.data as Clause, changeItem.itemOrder)
          );
          return {
            stack: [rawItem],
            preventNumberingRecursion: true,
            margin: [0, 0, 0, PdfGen.fieldsSpacing]
          };
        }
        if (changeItem.state === DataGeneration.Removed) {
          const rawItem = { text: clauseItemRemoved(changeItem.itemOrder + 1, changeItem.data.title) };

          return {
            stack: [rawItem],
            preventNumberingRecursion: true
          };
        }
      }));
    }
    const annexureHistory = buildListHistory('', snapshotHistory, snapshotHistory.latestExpiry, processAnnexureHistory);
    const annexRrl = determineReplacedRemovedLocked('', annexureHistory, annexures, DataGeneration.CarriedOver);

    if (annexRrl.length > 0) {
      mutableExtraContent.push(...annexRrl.map(changeItem => {
        if ([DataGeneration.Added, DataGeneration.Replaced].includes(changeItem.state)) {
          const rawItem = { text: (changeItem.state === DataGeneration.Replaced ? annexItemReplaced : annexItemAdded)(changeItem.data?.label, changeItem.data.name) };
          return {
            stack: [rawItem],
            preventNumberingRecursion: true,
            margin: [0, 0, 0, PdfGen.fieldsSpacing]
          };
        }
        // As previous generations may have already deleted an annexure, we will only be showing
        // a deletion item if it was deleted in this generation
        if (changeItem.state === DataGeneration.Removed && find(annexures, annex => annex.id === changeItem.id)) {
          const rawItem = { text: annexItemRemoved(changeItem.data?.label, changeItem.data.name) };

          return {
            stack: [rawItem],
            preventNumberingRecursion: true
          };
        }
      }));
    }
  }
}
