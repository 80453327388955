import { DiffCollection } from '../form/DiffCollection';
import { isPathInAnyHierachy } from './isPathInAnyHierachy';

export function isVariationPresent(pathWatchList: string[][], changeSet: DiffCollection | null) {
  if (!changeSet) return false;
  let anyMarkedVariation = false;
  for (const path of pathWatchList) {
    anyMarkedVariation = isPathInAnyHierachy(path, changeSet);
    if (anyMarkedVariation) {
      break;
    }
  }
  return anyMarkedVariation;
}
