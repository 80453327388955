import { SigningPartySourceType, SigningPartySourceTypeToStringPrint } from '@property-folders/contract';
import { ISignatureSectionSigner } from './signatureSection';
import { uniqBy } from 'lodash';
import { commaAndReducer } from '../../formatting/string-composites';

const displayOrder = [
  'Vendor',
  'Purchaser',
  'Agent'
];

export function generatePartySummaryGroupings(signers: ISignatureSectionSigner[]) {

  const partyGroupings = new Map<string, ISignatureSectionSigner[]>();
  for (const party of signers??[]) {
    const sourceTypeString = SigningPartySourceTypeToStringPrint(party.partyTypeEnum??SigningPartySourceType.Error);
    const valArr = [...(partyGroupings.get(sourceTypeString) ?? []), party];
    partyGroupings.set(sourceTypeString, valArr);
  }

  const groups = [...partyGroupings.entries()??[]].sort(([groupLabelA], [groupLabelB]) => {
    const a = displayOrder.findIndex(i=>i===groupLabelA);
    const b = displayOrder.findIndex(i=>i===groupLabelB);
    if (a === -1 && b === -1) {
      return 0;
    }
    if (a === -1) {
      return 1;
    }
    if (b === -1) {
      return -1;
    }
    return a - b;
  });

  return groups.map(([groupLabel, parties]) => `${uniqBy(
    parties.map(p=>p.sourceHierarchy?.[1]?.accessKey==='namedExecutors'
      ? { ...p,originalPartySourceId: p.sourceHierarchy[1].itemId }
      : p
    ), 'originalPartySourceId'
  ).map(p=>p.partyIdentifier).join(', ')} (${groupLabel})`);
}

export function generatePartiesSummaryString(signers: ISignatureSectionSigner[]) {
  const groupStrings = generatePartySummaryGroupings(signers);
  return groupStrings.length > 0 ? groupStrings.reduce(commaAndReducer) : '';
}