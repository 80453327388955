import { PDFDocument } from 'pdf-lib';

export const stitch = async (pdfs: (Uint8Array | ArrayBuffer)[]): Promise<Uint8Array | ArrayBuffer> => {
  if (pdfs?.length < 2) return pdfs?.[0];

  const mergedPdf = await PDFDocument.load(pdfs[0]);

  for (let i = 1; i < pdfs.length; ++i) {
    const toMerge = await PDFDocument.load(pdfs[i]);
    const copiedPages = await mergedPdf.copyPages(toMerge, toMerge.getPageIndices());
    copiedPages.forEach((page) => mergedPdf.addPage(page));
  }

  return await mergedPdf.save();
};
