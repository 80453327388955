export const sectionTitles = {
  properties: { num: 1, title: 'PROPERTY' },
  vendors: { num: 2, title: 'VENDOR' },
  agent: { num: 3, title: 'AGENT' },
  sale: { num: 4, title: 'SALE' },
  saleMethod: { num: 5, title: 'METHOD OF SALE' },
  particulars: { num: 6, title: 'PROPERTY AND SALE PARTICULARS' },
  marketing: { num: 7, title: 'MARKETING AND OTHER COSTS' },
  professionalFee: { num: 8, title: 'PROFESSIONAL FEE' }
};

export const subsectionTitles = {
  settlePeriod: 'Settlement',
  inclusions: 'Inclusions',
  exclusions: 'Exclusions',
  tenant: 'Tenancies',
  encroach: 'Encroachments and fences not on boundaries',
  notiWorks: 'Alterations and improvements erected without consent',
  vendorWorks: 'Vendor’s Works',
  cgtWith: 'CGT withholding',
  gstWith: 'GST withholding at Settlement',
  pool: 'Pool compliance',
  gstIssues: 'GST Conditions of Sale',
  marketingCosts: 'Vendor’s Marketing Costs',
  periodicPress: 'Periodic Press Promotion Budget',
  disclosureOfBenefits: 'Disclosure of Benefits',
  adminFee: 'Administration Fee',
  auctionFee: 'Auction Fee',
  searches: 'Statutory Searches and legal documentation fees',
  specialTerms: 'Additional Conditions',
  form1AndSearches: 'Form 1 and Searches',
  reaformsTransactionFee: 'Greatforms Transaction Fee'
};
