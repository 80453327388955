import { uuidv4 } from 'lib0/random';

export enum FieldType {
  // the value of a filled field
  FieldValue,
  // the timestamp that a field was filled
  FieldTimestamp,
  // a system value
  System,
  // a serve value
  Serve,
  // the witness for a filled field
  FieldWitness,
  // For legacy form signing - render the value and timestamp as one image
  // FieldValueLegacy
  PartyTimestamp
}

export function fieldTypeRequiresFieldDefinition(fieldType: FieldType) {
  switch (fieldType) {
    case FieldType.System:
    case FieldType.Serve:
    case FieldType.PartyTimestamp:
      return false;
    case FieldType.FieldValue:
    case FieldType.FieldTimestamp:
    case FieldType.FieldWitness:
    default:
      return true;
  }
}

export class FieldNamePrefix {
  static readonly FieldValue = 'FIELD.';
  static readonly FieldTimestamp = 'TS.';
  static readonly System = 'SYS.';
  static readonly Serve = 'SERVE.';
  static readonly FieldWitness = 'WIT.';
  static readonly PartyTimestamp = 'PTS.';
}

export type SystemField =
  // date of final signature
  | 'DATE_OF_AGREEMENT'
  // date of final signature + agency expiry days
  | 'DATE_OF_EXPIRY'
  // footer text showing Controlled Fully Signed/Controlled Pending Signatures
  | 'SIGNING_STATUS'
  // either now() or the date of final signature, formatted for the footer
  | 'DATE_OF_FILL'
  // footer text as `SIGNING_STATUS` + `DATE_OF_FILL`
  | 'SIGNING_AND_DATE'
  | 'NO_OP';

export type ServeField =
  | 'PURCHASER_NAME'
  | 'PURCHASER_ADDRESS'
  | 'CONTRACT_DATE';
export type ParsedField =
  | undefined
  | { type: FieldType.FieldValue | FieldType.FieldTimestamp | FieldType.FieldWitness, fieldId: string }
  | { type: FieldType.System, fieldId: SystemField }
  | { type: FieldType.Serve, fieldId: ServeField }
  | { type: FieldType.PartyTimestamp, partyId: string };

export function parseFieldName(fieldName: string): ParsedField {
  if (fieldName.startsWith(FieldNamePrefix.FieldValue)) {
    return {
      type: FieldType.FieldValue,
      fieldId: fieldName.replace(FieldNamePrefix.FieldValue, '')
    };
  }

  if (fieldName.startsWith(FieldNamePrefix.FieldWitness)) {
    return {
      type: FieldType.FieldWitness,
      fieldId: fieldName.replace(FieldNamePrefix.FieldWitness, '')
    };
  }

  if (fieldName.startsWith(FieldNamePrefix.FieldTimestamp)) {
    return {
      type: FieldType.FieldTimestamp,
      fieldId: fieldName.replace(FieldNamePrefix.FieldTimestamp, '')
    };
  }

  if (fieldName.startsWith(FieldNamePrefix.System)) {
    return {
      type: FieldType.System,
      fieldId: fieldName.replace(FieldNamePrefix.System, '').split('.')[0] as SystemField
    };
  }

  if (fieldName.startsWith(FieldNamePrefix.Serve)) {
    return {
      type: FieldType.Serve,
      fieldId: fieldName.replace(FieldNamePrefix.Serve, '').split('.')[0] as ServeField
    };
  }

  if (fieldName.startsWith(FieldNamePrefix.PartyTimestamp)) {
    return {
      type: FieldType.PartyTimestamp,
      partyId: fieldName.replace(FieldNamePrefix.PartyTimestamp, '').split('.')[0]
    };
  }

  return undefined;
}

export function buildFieldName(field: Partial<ParsedField>): string {
  switch (field?.type) {
    case FieldType.FieldTimestamp:
      return `${FieldNamePrefix.FieldTimestamp}${field.fieldId}`;
    case FieldType.FieldValue:
      return `${FieldNamePrefix.FieldValue}${field.fieldId}`;
    case FieldType.FieldWitness:
      return `${FieldNamePrefix.FieldWitness}${field.fieldId}`;
    case FieldType.System:
      return `${FieldNamePrefix.System}${field.fieldId}.${uuidv4()}`;
    case FieldType.Serve:
      return `${FieldNamePrefix.Serve}${field.fieldId}.${uuidv4()}`;
    case FieldType.PartyTimestamp:
      return `${FieldNamePrefix.PartyTimestamp}${field.partyId}.${uuidv4()}`;
  }

  return '';
}
