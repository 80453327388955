export function nestedOl(header: string, olContents: any, returnArray = true) {
  return returnArray
    ? [
      header,
      {
        ol: olContents
      }
    ]
    : {
      stack: [
        header,
        {
          ol: olContents
        }
      ]
    };
}
