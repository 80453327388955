import { QuadState } from '../types/QuadState';

export function quadStateFilter(mainBool: boolean | undefined, unknownBool: boolean | undefined) {
  return unknownBool
    ? QuadState.AssertUnknown
    : mainBool
      ? QuadState.True
      : mainBool === false
        ? QuadState.False
        : QuadState.NotSet;
}

export type QuadStateFilterFn = typeof quadStateFilter;

export function quadStateEnableOrStrike(strikeBool: boolean | undefined, enableBool: boolean | undefined) {
  return enableBool === true
    ? QuadState.AssertUnknown
    : enableBool == null
      ? QuadState.NotSet
      : strikeBool
        ? QuadState.True
        : strikeBool === false
          ? QuadState.False
          : QuadState.NotSet;
}

export const quadStateEnableOrStrikeRules = {
  onTrue: false,
  onFalse: false,
  onSelfTrue: true,
  onUnselectAll: null
};

export function quadStateBoolText(mainBool: boolean | undefined, unknownBool: boolean | undefined): string {
  if (unknownBool || typeof mainBool === 'undefined') return 'unknown';
  return mainBool
    ? 'yes'
    : 'no';
}
