import { Predicate } from '../../predicate';
import { minimumFontSize } from './constants';

type ContainsBI<T extends string> = T extends `${infer _}/-/${infer _}/-/${infer _}` | `${infer _}*-*${infer _}*-*${infer _}` ? {
  text: NonNullable<string | {
      text: string;
      bold: boolean;
  } | {
      text: string;
      italics: boolean;
  }>[];
  fontSize?: number;
} : T;

/** finds tokens to mark a range of text with Bold or Italic
 *
 * Example
 * '*-*This text is bold*-*, this text is normal, /-/this text is italic/-/
 *
 * Does not support Bold + Italics (yet), will result in a words is not defined if you attempt to put
 * bold and italic symbols directly next to each other at least
 *
 * @param str
 * @param includeFontSize
 * @returns
 */
export function formatBI<T extends string>(str: T, includeFontSize?: boolean, styleExtra?: string): ContainsBI<T> {
  if (str.includes('/-/') || str.includes('*-*')) {
    const rval = {
      text: str.split('/-/').map((s,idx) => idx%2 === 1
        ? { text: s, italics: true }
        : s.split('*-*').map((s,idx)=>idx%2===1
          ?{ text: s, bold: true }
          : s
        )
      ).flat().filter(Predicate.isTruthy),
      fontSize: includeFontSize ? minimumFontSize : undefined
    };
    if (rval.text.filter(a=>!a).length > 0) console.log(str);
    if (styleExtra) rval.style = styleExtra;
    return rval as ContainsBI<T>;
  }
  return str as ContainsBI<T>;
}
