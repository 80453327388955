import { InstanceHistory, MaterialisedPropertyData } from '@property-folders/contract';
import { summariseAddressesOrTitles } from '../../../yjs-schema/property';
import { FormTypes } from '../../../yjs-schema/property/form';
import { buildSigningTimelines } from '../../dataExtract';
import { friendlyDateFormatter } from '../../formatting';

export function generateSignedLineageHistoryString(snapshotHistory: InstanceHistory, latestData: MaterialisedPropertyData) {
  const latestInstanceGrouping = snapshotHistory?.signingTimelines ?? buildSigningTimelines(snapshotHistory).instanceSets;
  const latestGroup = latestInstanceGrouping[latestInstanceGrouping.length-1];
  const earliestTitle = FormTypes[latestGroup[0].formCode].label;
  const subsequentVariations = latestGroup.filter(i=>i.signing?.session?.completedTime).slice(1).map(inst=> `as varied by ${FormTypes[inst.formCode].label} dated ${friendlyDateFormatter(new Date(inst?.signing?.session?.completedTime||0))}`);
  const subsequentVariationText = subsequentVariations.length > 0 ? ', '+subsequentVariations.join(',') : '';
  const titleSummarised =  summariseAddressesOrTitles(latestData);
  return `Parties entered into a ${earliestTitle} in respect of ${titleSummarised ?? ''} dated ${friendlyDateFormatter(new Date(latestGroup[0]?.signing?.session?.completedTime??''))}${subsequentVariationText} `;
}